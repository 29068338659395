import { defineMessages } from 'react-intl.macro';

const labMessages = defineMessages({
    pageTitle: {
        id: 'LabPage.pageTitle',
        description: 'Lab page title',
        defaultMessage: 'Lab details',
    },
    tableHeaderStudent: {
        id: 'lab.header.student',
        defaultMessage: 'Student',
    },
    noTrainings: {
        id: 'lab.noTrainings',
        defaultMessage: 'No trainings for lab',
    },
    tableHeaderLabStatus: {
        id: 'lab.header.labStatus',
        defaultMessage: 'Lab Status',
    },
    tableHeaderRegion: {
        id: 'lab.header.region',
        defaultMessage: 'Region',
    },
    tableHeaderControls: {
        id: 'lab.header.controls',
        defaultMessage: 'Access Controls',
    },
    openNavigation: {
        id: 'lab.navigation.open',
        defaultMessage: 'Open navigation',
    },
    closeNavigation: {
        id: 'lab.navigation.close',
        defaultMessage: 'Close navigation',
    },
    somethingWentWrongTrainings: {
        id: 'lab.modal.somethingWentWrongTrainings',
        defaultMessage: 'Something went wrong',
    },
    cannotGetStudentLabs: {
        id: 'lab.modal.cannotGetStudentLabs',
        defaultMessage: 'Cannot get the student labs',
    },
    close: {
        id: 'lab.modal.cannotGetStudentLabs.close',
        defaultMessage: 'Close',
    },
    closeModalLabelText: {
        id: 'lab.modal.cannotGetStudentLabs.closeLabelText',
        defaultMessage: 'Close dialog',
    },
    dismissAlert: {
        id: 'lab.alert.dismissAlert',
        defaultMessage: 'Dismiss alert',
    },
    ingressControl: {
        id: 'lab.button.ingress',
        defaultMessage: 'Ingress',
    },
    labTitle: {
        id: 'lab.title',
        defaultMessage: 'Lab',
    },
    tryAgain: {
        id: 'lab.tryAgain',
        defaultMessage: 'Try to {operation} the lab again.',
    },
});

export default labMessages;
