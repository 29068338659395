import React from 'react';

export const FlagContext = React.createContext();

const toBooleanValue = val => /^true$/i.test(val);
export const FlagContextProvider = ({ children }) => (
    <FlagContext.Provider
        value={{
            instructorAssignment: toBooleanValue(process.env.instructorAssignment),
            showClassList: toBooleanValue(process.env.showClassList),
            classroomResize: toBooleanValue(process.env.classroomResize),
        }}
    >
        {children}
    </FlagContext.Provider>
);

export const useFlags = () => React.useContext(FlagContext);
