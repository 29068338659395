import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { AppLayout, Button, Modal, Alert } from '@amzn/awsui-components-react';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import { Helmet } from 'react-helmet'

import { executeRequest } from 'modules/api';
import { useClassroomData } from 'data/useClassroomData';
import { LabNavigation, IngressTable, HeroBanner } from 'components';
import messages from './Lab.messages';
import { getStudentTrainingsByClassroomId } from 'graphql/queries';
import { isEmpty } from 'utils/helpers';

const { labTitle, cannotGetStudentLabs, somethingWentWrongTrainings, close, tryAgain } = messages;

const Lab = props => {
    const {
        match: {
            params: { classroomId, labId },
        },
    } = props;

    const { formatMessage } = useIntl();
    const translatedLabTitle = formatMessage(labTitle);
    const classData = useClassroomData(classroomId);
    const { content } = classData;
    const currentLabIndex = _findIndex(content, ['contentId', decodeURIComponent(labId)]);
    const [labNumber, setLabNumber] = useState(currentLabIndex === -1 ? 0 : currentLabIndex);
    const [trainingLoading, setTrainingLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [trainingData, setTrainingData] = useState();
    const [toggleError, setToggleError] = useState(false);

    // NOTE: this is a temporary solution until a centralized query mechanism is in place
    const loadingClassData = isEmpty(classData);

    /**
     * Currently we are getting all training from the activity service.
     * This has some complications.
     *
     * 1. A training record can reference old lab arns
     * 2. A training record may be in an error state
     * 3. We do not keep state, each student has more than one "active" lab, this is because
     * we are not onboarded to the labs hook notification. https://sim.amazon.com/issues/BKR-2236
     */
    const fetchQuery = async (operation, params) => {
        setTrainingLoading(true);
        try {
            const data = await executeRequest({ operation, params });
            setTrainingData(data);
        } catch (error) {
            setError(error);
        } finally {
            setTrainingLoading(false);
        }
    };

    /**
     * https://overreacted.io/a-complete-guide-to-useeffect/#but-i-cant-put-this-function-inside-an-effect
     */
    const trainingFetch = useCallback(() => {
        fetchQuery(getStudentTrainingsByClassroomId, { classroomId });
    }, [classroomId]);

    useEffect(() => {
        trainingFetch();
    }, [trainingFetch]);

    const filterOutOldLabArns = ({ studentTrainings = [] }) =>
        studentTrainings.filter(training => {
            return training.arn === decodeURIComponent(labId);
        });

    const studentTrainingsByCurrentLabId =
        !trainingLoading &&
        !isError &&
        trainingData &&
        filterOutOldLabArns(trainingData.getStudentTrainingsByClassroomId);

    const appLayoutLabels = {
        navigationClose: formatMessage(messages.closeNavigation),
        navigationToggle: formatMessage(messages.openNavigation),
    };

    if (loadingClassData) return null;

    return (
        <Fragment>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)} - {`${translatedLabTitle} ${labNumber + 1}`}</title>
            </Helmet>
            <HeroBanner title={_get(classData, 'course.title')} />
            <AppLayout
                navigation={
                    <LabNavigation
                        onLabSelection={() => {
                            setToggleError(false);
                            trainingFetch();
                        }}
                        content={content}
                        labTitle={translatedLabTitle}
                        setLabNumber={setLabNumber}
                        labNumber={labNumber}
                    />
                }
                navigationWidth={160}
                toolsHide
                contentType="table"
                labels={appLayoutLabels}
                content={
                    <Fragment>
                        <Modal
                            visible={!!isError}
                            onDismiss={() => setError(false)}
                            header={formatMessage(somethingWentWrongTrainings)}
                            closeLabel={formatMessage(messages.closeModalLabelText)}
                            footer={
                                <span className="awsui-util-f-r">
                                    <Button onClick={() => setError(false)} variant="primary">
                                        {formatMessage(close)}
                                    </Button>
                                </span>
                            }
                        >
                            {formatMessage(cannotGetStudentLabs)}
                        </Modal>

                        <Alert
                            className="awsui-util-mb-m"
                            visible={toggleError}
                            onDismiss={() => setToggleError(false)}
                            dismissible
                            dismissLabel={formatMessage(messages.dismissAlert)}
                            type="error"
                            header={formatMessage(somethingWentWrongTrainings)}
                        >
                            {formatMessage(tryAgain, {
                                operation: content[labNumber].isActive ? 'Deactivate' : 'Active',
                            })}
                        </Alert>

                        <IngressTable
                            labId={decodeURIComponent(labId)}
                            classroomId={classroomId}
                            loading={trainingLoading}
                            studentTrainings={
                                studentTrainingsByCurrentLabId &&
                                studentTrainingsByCurrentLabId.length > 0
                                    ? studentTrainingsByCurrentLabId
                                    : []
                            }
                            title={`${translatedLabTitle} ${labNumber + 1}`}
                            trainingRefetch={trainingFetch}
                            trainingLoading={trainingLoading}
                            labActive={content[labNumber].isActive}
                            openTab={window.open}
                            setToggleError={setToggleError}
                        />
                    </Fragment>
                }
            ></AppLayout>
        </Fragment>
    );
};

export default Lab;
