/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClassroom = /* GraphQL */ `
  query GetClassroom($classroomId: String!, $providerArn: String) {
    getClassroom(classroomId: $classroomId, providerArn: $providerArn) {
      classroom {
        classroomId
        langLocale
        courseId
        providerArn
        courseTitle
        courseAbstract
        courseDescription
        courseLevel
        startsOn
        endsOn
        locationData {
          timezone
          displayName
          longitude
          latitude
          locationType
          virtualUrl
          physicalAddress {
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
          }
        }
        studentGuide {
          custom_vbid
        }
        callsToAction {
          locationType
          url
          label
          actionType
          icon
          iconPosition
        }
        accessCodes {
          accessCode
          accessCodeCapacity
          accessCodeCapacityUsed
        }
        classCapacity
        supportedRegions
        createdBy
      }
      course {
        courseId
        title
        description
        langLocale
        abstract
        level
      }
      content {
        contentId
        title
        description
        level
        duration
        durationUnit
        isActive
        supportedRegions
      }
    }
  }
`;
export const getStudentTrainingsByClassroomId = /* GraphQL */ `
  query GetStudentTrainingsByClassroomId($classroomId: String!) {
    getStudentTrainingsByClassroomId(classroomId: $classroomId) {
      studentTrainings {
        lastUpdated
        metaData {
          preferredLangLocale
          labId
          labUrl
          labStatus
          region
        }
        trainingId
        context
        created
        requestClientToken
        arn
        state
        fulfillmentStatus
        userKey
        studentNumber
      }
    }
  }
`;
export const getIngressUrl = /* GraphQL */ `
  query GetIngressUrl($input: GetIngressUrlInput!) {
    getIngressUrl(input: $input) {
      labId
      consoleUrl
      endsOn
      status
    }
  }
`;
export const getCourseVersions = /* GraphQL */ `
  query GetCourseVersions($input: GetCourseVersionsInput!) {
    getCourseVersions(input: $input) {
      courseVersions {
        courseId
        versionId
        createdOn
        status
        availability
      }
      nextToken
    }
  }
`;
export const getCourseVersion = /* GraphQL */ `
  query GetCourseVersion($courseVersionId: String!) {
    getCourseVersion(courseVersionId: $courseVersionId) {
      courseVersion {
        courseVersionId
        createdOn
        availability
        status
        courseName
        supportedLocales
        blueprintData {
          blueprintName
          arn
        }
        resourceData {
          locale
          description
          title
        }
        contentVersion
      }
    }
  }
`;
export const getCourseArns = /* GraphQL */ `
  query GetCourseArns {
    getCourseArns {
      courses {
        courseId
        title
      }
    }
  }
`;
export const getProviderArns = /* GraphQL */ `
  query GetProviderArns {
    getProviderArns {
      providers
    }
  }
`;
export const getProvider = /* GraphQL */ `
  query GetProvider($input: GetProviderInput!) {
    getProvider(input: $input) {
      provider {
        name
        arn
        type
      }
    }
  }
`;
export const listUserRelationships = /* GraphQL */ `
  query ListUserRelationships($input: ListUserRelationshipsInput!) {
    listUserRelationships(input: $input) {
      relationships
    }
  }
`;
export const listClassroomRelationships = /* GraphQL */ `
  query ListClassroomRelationships($input: ListClassroomRelationshipsInput!) {
    listClassroomRelationships(input: $input) {
      emails
    }
  }
`;
export const listUserClassrooms = /* GraphQL */ `
  query ListUserClassrooms($input: ListClassroomsInput!) {
    listUserClassrooms(input: $input) {
      classrooms {
        classroomArn
        providerArn
        langLocale
        courseId
        startsOn
        endsOn
        locationData {
          timezone
          displayName
          longitude
          latitude
          locationType
          virtualUrl
          physicalAddress {
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
          }
        }
      }
      nextToken
    }
  }
`;
export const listProviderClassrooms = /* GraphQL */ `
  query ListProviderClassrooms($input: ListClassroomsInput!) {
    listProviderClassrooms(input: $input) {
      classrooms {
        classroomArn
        providerArn
        langLocale
        courseId
        startsOn
        endsOn
        locationData {
          timezone
          displayName
          longitude
          latitude
          locationType
          virtualUrl
          physicalAddress {
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
          }
        }
      }
      nextToken
    }
  }
`;
