import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import { PageWrapper, MyClassesTable, ProviderClassesTable } from 'components';
import { paths } from 'utils/paths';
import { useProvider } from 'data/ProviderContext';
import messages from './ClassListPage.messages';
import { useUserInfo } from 'utils/userInfo';

const ClassListPage = () => {
    const { userIsTrainingCoordinator } = useUserInfo();
    const { formatMessage } = useIntl();
    const history = useHistory();
    const providerName = useProvider()?.name;
    const ClassesComponent = userIsTrainingCoordinator ? ProviderClassesTable : MyClassesTable;

    return (
        <PageWrapper>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <div className="awsui-util-action-stripe-large">
                <div className="awsui-util-action-stripe-title">
                    <h1 className="awsui-text-large awsui-util-pb-n">{providerName}</h1>
                </div>
                <div className="awsui-util-action-stripe-group">
                    <Button onClick={() => history.push(paths.classPage('new'))}>
                        {formatMessage(messages.createClass)}
                    </Button>
                </div>
            </div>
            <ClassesComponent />
        </PageWrapper>
    );
};

export default ClassListPage;
