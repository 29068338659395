import React, { useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import { Button, ButtonDropdown } from '@amzn/awsui-components-react';
import logo from './logo.svg';
import { Link, useLocation } from 'react-router-dom';
import _get from 'lodash/get';

import { sendClientLog } from 'modules/api';
import { supportedLocales } from '../../i18n';
import { ClassroomContext } from 'data/ClassroomContext';
import { useUserInfo, useLocale, paths } from 'utils';
import messages from './Header.messages';
import './Header.css';

const localeSelections = supportedLocales.reduce((accumulator, current) => {
    accumulator.push({
        text: current,
        id: current,
    });

    return accumulator;
}, []);

const ClassHeader = () => {
    const { formatMessage } = useIntl();
    const { pathname } = useLocation();
    const {
        classData: { classroom },
    } = useContext(ClassroomContext);
    const [{ canChangeLanguage, locale }, localeSet] = useLocale();

    const { email } = useUserInfo();
    const classroomId = _get(classroom, 'classroomId');
    const isClassPage = new RegExp(`/class/${classroomId}/?$`).test(pathname);

    const onItemClick = async event => {
        if (event.detail.id === 'signout') {
            await Auth.signOut();
        }
    };

    const onLanguageChange = async e => {
        const locale = e.detail.id;

        localeSet({ locale, userSelected: true });

        await sendClientLog({
            type: 'AppClick',
            metrics: { InstructorLanguageSelect: [1, 'Count'] },
            attributes: { locale },
        });
    };

    return (
        <header className="site-header awsui-util-ph-xl awsui-util-pv-m">
            <div>
                <Link
                    to={{
                        pathname: classroomId ? paths.classPage(classroomId) : paths.root,
                    }}
                    title={
                        classroomId
                            ? formatMessage(messages.detailPage)
                            : formatMessage(messages.homepage)
                    }
                >
                    <img src={logo} alt={formatMessage(messages.awsTAndC)} />
                </Link>
            </div>
            <div>
                {isClassPage ? (
                    <Button>
                        <Link to={paths.classDetailPage(classroomId)}>
                            {formatMessage(messages.goToDetail)}
                        </Link>
                    </Button>
                ) : null}
                {canChangeLanguage ? (
                    <ButtonDropdown
                        items={localeSelections}
                        onItemClick={e => {
                            onLanguageChange(e);
                        }}
                    >
                        {locale}
                    </ButtonDropdown>
                ) : null}
                {email ? (
                    <ButtonDropdown
                        items={[
                            {
                                text: email,
                                id: 'email',
                                disabled: true,
                            },
                            {
                                text: formatMessage(messages.signout),
                                id: 'signout',
                            },
                        ]}
                        onItemClick={onItemClick}
                    >
                        {formatMessage(messages.accountDropdown)}
                    </ButtonDropdown>
                ) : null}
            </div>
        </header>
    );
};

export default ClassHeader;
