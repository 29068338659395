import React from 'react';
import { injectIntl } from 'react-intl';
import { Button } from '@amzn/awsui-components-react';

import { getStudentURL, isBeforeTimestamp, adjustUnixTimestamp } from 'utils/helpers';
import messages from './ClassListHeader.messages';
import './ClassListHeader.css';

const ClassListHeader = ({ intl: { formatMessage }, data }) => {
    const { classroomId, accessCodes, startsOn } = data.classroom;
    const [accessCodeObj] = accessCodes || [];
    const hasTheClassStarted = isBeforeTimestamp(adjustUnixTimestamp(startsOn));
    return (
        <section data-testid="class-list-section">
            <div>
                <h2 data-testid="class-list-subheader">{formatMessage(messages.sectionTitle)}</h2>
                <div>
                    <Button
                        icon="external"
                        href={getStudentURL(classroomId, accessCodeObj?.accessCode)}
                        iconAlign="right"
                    >
                        {formatMessage(messages.studentView)}
                    </Button>
                </div>
            </div>
            {!hasTheClassStarted && (
                <p data-testid="early-warning">{formatMessage(messages.tooEarlyWarning)}</p>
            )}
        </section>
    );
};

export default injectIntl(ClassListHeader);
