import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    sectionTitle: {
        id: 'classListHeader.title.heading',
        description: 'Section title for the class lab list',
        defaultMessage: 'Labs',
    },
    callToActionButtonText: {
        id: 'classListHeader.callToAction.text',
        description: 'Text for the call to action button',
        defaultMessage: 'Student Guide',
    },
    studentGuideErrorTitle: {
        id: 'classListHeader.studentGuideModal.errorTitle',
        description: 'Title for the student guide modal when there was an error',
        defaultMessage: 'An error has occurred',
    },
    studentGuideErrorContent: {
        id: 'classListHeader.studentGuideModal.errorContent',
        description: 'Content for the student guide modal when there was an error',
        defaultMessage: 'Please try again.',
    },
    studentGuideErrorModalActionButton: {
        id: 'classListHeader.studentGuideModal.errorActionButton',
        description: 'Text for the error modal button',
        defaultMessage: 'Close',
    },
    tooEarlyWarning: {
        id: 'classLabListHeader.warning.tooEarly',
        description: "Warning message if the class hasn't started",
        defaultMessage: 'Hands on Training cannot be launched until the day of the class',
    },
    studentView: {
        id: 'classLabListHeader.btn.studentView',
        description: 'Button label for Student UI',
        defaultMessage: 'Student View',
    },
});

export default messages;
