import React from 'react';
import { useEffect, useState, Fragment } from 'react';
import _get from 'lodash/get';
import { Button, Table, Toggle, Modal } from '@amzn/awsui-components-react';
import { Auth } from 'aws-amplify';
import { nanoid } from 'nanoid';
import { useIntl } from 'react-intl';
import _sortBy from 'lodash/sortBy';
import messages from './IngressTable.messages';
import { getIngressUrl, clientLog, updateTrainingPermissions } from '../../graphql';
import { executeRequest } from '../../modules';
import { createLogMessage } from '../../utils/createLogMessage';

const onToggleTraining = async ({ classroomId, labId, isActive }) =>
    await executeRequest({
        operation: updateTrainingPermissions,
        params: {
            input: {
                arn: labId,
                classroomId,
                isActive: isActive,
                idempotencyToken: nanoid(),
            },
        },
    });

const sendAppClickEvent = async ({ metricName, ...rest }) => {
    try {
        await executeRequest({
            operation: clientLog,
            params: {
                input: {
                    body: JSON.stringify(
                        createLogMessage({
                            payload: {
                                type: 'AppClick',
                                attributes: { ...rest },
                                metrics: { [metricName]: [1, 'Count'] },
                            },
                            globals: window,
                        })
                    ),
                },
            },
        });
    } catch (_) {
        console.log('failed analytics call');
    }
};

const {
    ingressControl,
    ingressingControl,
    tableHeaderControls,
    tableHeaderStudent,
    studentLabEnded,
    cannotIngress,
    close,
    refresh,
    tableEmpty,
    active,
    inactive,
} = messages;

const isConsoleReady = labStatus => labStatus === 'CONSOLE_AVAILABLE';

const removeInvalidTrainings = (studentTrainings = []) => {
    return Object.values(
        studentTrainings.reduce((acc, training) => {
            const { userKey, metaData } = training;
            if (!isConsoleReady(metaData.labStatus)) return acc;

            if ([userKey] in acc) {
                if (training.lastUpdated > _get(acc, 'userKey.lastUpdated', 0)) {
                    acc[userKey] = training;
                }
            } else {
                acc[userKey] = training;
            }

            return acc;
        }, {})
    );
};

const IngressTable = ({
    studentTrainings,
    openTab,
    loading,
    title,
    trainingLoading,
    trainingRefetch,
    labActive,
    classroomId,
    labId,
    setToggleError,
}) => {
    const { formatMessage } = useIntl();
    const [instructorUserKey, setInstructorKey] = useState();
    const [isSending, setIsSending] = useState(false);
    const [isError, setError] = useState(false);
    const [labIdIngressing, setLabIdIngressing] = useState();
    const [checked, setChecked] = React.useState(labActive);

    useEffect(() => {
        setChecked(labActive);
    }, [labActive]);

    const ingressConsole = async (labId, userKey) => {
        if (!labId || !userKey) {
            setError(true);
            setIsSending(false);
            return;
        }
        const consoleIngress = await queryIngressUrl(labId, userKey);
        if (consoleIngress && consoleIngress.consoleUrl) {
            openTab(consoleIngress.consoleUrl);
            setIsSending(false);
        }
    };

    const queryIngressUrl = async (labId, studentUserKey) => {
        if (isSending) return;
        setLabIdIngressing(labId);
        setIsSending(true);
        await sendAppClickEvent({
            metricName: 'instructorClick',
            instructorUserKey,
            studentUserKey,
            labId,
        });

        try {
            const { getIngressUrl: payload } = await executeRequest({
                operation: getIngressUrl,
                params: {
                    input: {
                        classroomId,
                        instructorUserKey,
                        studentUserKey,
                        labId,
                    },
                },
            });
            return payload;
        } catch (error) {
            setError(true);
        } finally {
            setIsSending(false);
        }
    };

    useEffect(() => {
        async function getUserKey() {
            const {
                idToken: {
                    payload: { sub: instructorUserKey },
                },
            } = await Auth.currentSession();

            setInstructorKey(instructorUserKey);
        }

        getUserKey();
    }, []);

    const columnDefinitions = [
        {
            id: 'student',
            header: formatMessage(tableHeaderStudent),
            cell: ({ studentNumber }) => studentNumber || '-',
        },
        {
            id: 'alt',
            header: formatMessage(tableHeaderControls),
            cell: ({ userKey, metaData: { labId } }) => (
                <Button
                    onClick={() => !isSending && ingressConsole(labId, userKey)}
                    disabled={isSending && labId === labIdIngressing}
                    loading={isSending && labId === labIdIngressing}
                >
                    {isSending && labId === labIdIngressing
                        ? formatMessage(ingressingControl)
                        : formatMessage(ingressControl)}
                </Button>
            ),
        },
    ];

    return (
        <>
            <Modal
                visible={isError}
                onDismiss={() => setError(false)}
                header={formatMessage(cannotIngress)}
                closeLabel={formatMessage(messages.closeModalLabelText)}
                footer={
                    <span className="awsui-util-f-r">
                        <Button onClick={() => setError(false)} variant="primary">
                            {formatMessage(close)}
                        </Button>
                    </span>
                }
            >
                {formatMessage(studentLabEnded)}
            </Modal>
            <Table
                resizableColumns={true}
                loading={loading}
                header={
                    <div>
                        <div className="awsui-util-action-stripe">
                            <div className="awsui-util-action-stripe-title">
                                <h2 className="awsui-util-d-ib">{title}</h2>
                                <Toggle
                                    ariaLabel="active-toggle"
                                    onChange={async ({ detail }) => {
                                        await onToggleTraining({
                                            classroomId,
                                            labId,
                                            isActive: detail.checked,
                                        })
                                            .then(() => {
                                                setChecked(detail.checked);
                                            })
                                            .catch(() => {
                                                setToggleError(true);
                                                setChecked(checked);
                                            });
                                    }}
                                    className="awsui-util-p-xs awsui-util-d-ib"
                                    checked={checked}
                                >
                                    {formatMessage(checked ? active : inactive)}
                                </Toggle>
                            </div>
                            <div className="awsui-util-action-stripe-group">
                                {
                                    <Fragment>
                                        <Button
                                            icon="refresh"
                                            iconAlt={formatMessage(refresh)}
                                            label={formatMessage(refresh)}
                                            disabled={trainingLoading}
                                            loading={trainingLoading}
                                            onClick={() => {
                                                !trainingLoading && trainingRefetch();
                                            }}
                                        ></Button>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                }
                columnDefinitions={columnDefinitions}
                items={_sortBy(removeInvalidTrainings(studentTrainings), ['studentNumber'])}
                empty={
                    <div className="awsui-util-t-c">
                        <div className="awsui-util-pt-s awsui-util-mb-xs">
                            <b>{formatMessage(tableEmpty)}</b>
                        </div>
                    </div>
                }
            ></Table>
        </>
    );
};

export default IngressTable;
