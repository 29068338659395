import { Auth } from 'aws-amplify';
import moment from 'moment-timezone';
import environment from '.config/environment';

export const nowTime = () => Date.now();

export const nowDateString = () => Date();

export const isBeforeTimestamp = classStartTimestamp => moment().isAfter(classStartTimestamp);

export const adjustUnixTimestamp = unixTimestamp => unixTimestamp * 1000;

export const isEmpty = obj =>
    [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

const { studentUI = {} } = environment(window);
export const getStudentURL = (classId, accessCode) =>
    `${studentUI.url}/class/${classId}` +
    (accessCode ? `?accessCode=${encodeURIComponent(accessCode)}` : '');

export const copyToClipboard = ({ globals, value }) => {
    if (value) {
        value = value.trim();
        if (value.indexOf('\n') >= 0) {
            // Append newline to multiline text as an application contention.
            // In lab sessions, multiline text often represents shell commands
            // that are intended to be executed in a terminal, where the trailing
            // newline executes the last one.
            value += '\n';
        }

        const tempInput = globals.document.createElement('textarea');
        tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
        tempInput.value = value;
        globals.document.body.appendChild(tempInput);
        tempInput.select();
        globals.document.execCommand('copy');
        globals.document.body.removeChild(tempInput);
    }
};

export const getCurrentUser = async () =>
    (await Auth.currentAuthenticatedUser({ bypassCache: true })).getSignInUserSession().getIdToken()
        .payload;

export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

/**
 * @param {Number} startsOn Start date/time in epoch format
 * @param {Number} endsOn End date/time in epoch format
 * @param {String} locale Self-explanatory, you should get this value from React-Intl
 * @return {String} Duration string in either days, hours, or minutes
 *
 * @note There is an assumption that all classes will be longer than 1 minute in duration
 */
export const getDuration = (startsOn, endsOn, locale) => {
    const start = moment.unix(startsOn);
    const end = moment.unix(endsOn);

    return ['minutes', 'hours', 'days']
        .map(u => {
            const diff = Math.ceil(end.diff(start, u, true));
            if (diff > 1) {
                const dur = moment.duration(diff, u);
                return dur.locale(locale).humanize();
            }
            return null;
        })
        .filter(v => v)
        .pop();
};

/**
 * @typedef {Object} RetryOptions
 * @property {Number} [retries=3]
 * @property {Number} [interval=100]
 * @property {Boolean} [expBackoff=true] Adds exponential backoff between retries
 * @property {Boolean} [jitter=true] Adds jitter to retries
 * @property {Function<Boolean>} [retryPredicate] Fine tune when to retry, gets passed the caught error.
 */

/** @type {RetryOptions} */
const defaultOptions = {
    retries: 3,
    interval: 100,
    expBackoff: true,
    jitter: true,
};

/**
 * @param {Number} attempt
 * @param {RetryOptions} [options]
 */
export const calculateRetryDelay = (attempt, options = {}) => {
    const opts = { ...defaultOptions, ...options };
    // Avoid delays when running test suite.
    if (process.env.NODE_ENV === 'test') return 0;

    const expBackoff = opts.expBackoff ? Math.pow(2, attempt) : 1;
    const jitter = opts.jitter ? Math.random() : 1;
    return Math.floor(opts.interval * jitter * expBackoff);
};
