import React, { useState, useEffect } from 'react';
import { Modal } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import { Breadcrumbs, PageWrapper, ClassForm } from 'components';
import { createClassroom, addInstructorsToClassroom } from 'modules';

import messages from './ClassCreatePage.messages';
import { useLocale } from 'utils';
import localStoreageDB from 'modules/localStorageDb';
import { useFlags } from 'utils/flags';
import { classCreatePageBreadcrumb, classesPageBreadcrumb } from 'utils/breadcrumbs';
import { AppError, FETCH_ERROR_NAME } from 'utils/error';

const errorMessages = {
    'createClassroomV2::409': messages.createClass409Error,
    default: messages.noCreateContent,
};

const ClassCreatePage = () => {
    const { formatMessage } = useIntl();
    const [createClassError, createClassErrorSet] = useState();
    const [, setLocale] = useLocale();
    const selectedLocale = localStoreageDB.selectedLocale();

    useEffect(() => {
        setLocale({
            locale: selectedLocale,
        });
    }, [setLocale, selectedLocale]);

    const flags = useFlags();

    const onSubmit = async data => {
        const payload = await createClassroom(data);
        if (flags?.instructorAssignment && data?.instructors?.length) {
            await addInstructorsToClassroom(
                data.instructors,
                payload.classroomId,
                data.classroom.providerArn
            );
        }
        return payload;
    };

    const handleError = err => {
        new AppError(`operation ${err.path} returned a ${err.statusCode}`, {
            status: err.statusCode,
            type: FETCH_ERROR_NAME,
        });
        const msg = errorMessages[`${err.path}::${err.statusCode}`] || errorMessages.default;
        createClassErrorSet(msg);
    };

    return (
        <PageWrapper>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <Breadcrumbs
                items={[
                    classesPageBreadcrumb(formatMessage),
                    classCreatePageBreadcrumb(formatMessage),
                ]}
            />
            <ClassForm onSubmit={onSubmit} onError={handleError} />
            {createClassError ? (
                <Modal
                    visible
                    data-testid="class-create-modal"
                    header={formatMessage(messages.noCreateHeader)}
                    onDismiss={() => createClassErrorSet(null)}
                    closeLabel={formatMessage(messages.closeModalButtonLabel)}
                >
                    {formatMessage(createClassError)}
                </Modal>
            ) : null}
        </PageWrapper>
    );
};

export default ClassCreatePage;
