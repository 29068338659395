import { defineMessages } from 'react-intl.macro';

import { sendClientLog } from 'modules/api';

export const messages = defineMessages({
    // BUTTONS + LABELS
    cancelButtonText: {
        id: 'classForm.cancelButtonText',
        defaultMessage: 'Cancel',
    },
    addButtonText: {
        id: 'classForm.addButtonText',
        defaultMessage: 'Add',
    },
    addButtonLabel: {
        id: 'classForm.addButtonLabel',
        defaultMessage: 'Add new class',
    },
    saveButtonText: {
        id: 'classForm.saveButtonText',
        defaultMessage: 'Save',
    },
    saveButtonLabel: {
        id: 'classForm.saveButtonLabel',
        defaultMessage: 'Save changes to class data',
    },
    addInstructorButtonText: {
        id: 'classForm.addInstructorButtonText',
        defaultMessage: 'Add instructor',
    },
    removeButtonText: {
        id: 'classForm.removeButtonText',
        defaultMessage: 'Remove',
    },
    doneButtonText: {
        id: 'classForm.doneButtonText',
        defaultMessage: 'Done',
    },
    okButtonText: {
        id: 'classForm.okButtonText',
        defaultMessage: 'OK',
    },
    createClassButtonText: {
        id: 'classForm.createClassButtonText',
        defaultMessage: 'Create class',
    },
    closeModalButtonLabel: {
        id: 'classForm.closeModalButtonLabel',
        defaultMessage: 'Close dialog',
    },

    // PAGE TITLES
    addPageTitle: {
        id: 'classForm.addPageTitle',
        defaultMessage: 'Create class',
    },
    editPageTitle: {
        id: 'classForm.editPageTitle',
        defaultMessage: 'Edit class',
    },

    // CONTAINER HEADERS
    courseInfoHeader: {
        id: 'classForm.courseInfoHeader',
        defaultMessage: 'Course information',
    },
    codeInfoHeader: {
        id: 'classform.codeInfoHeader',
        defaultMessage: 'Create student code',
    },
    classTimeHeader: {
        id: 'classForm.classTimeHeader',
        defaultMessage: 'Class time',
    },
    classProviderHeader: {
        id: 'classForm.classProviderHeader',
        defaultMessage: 'Class provider',
    },
    classLocationHeader: {
        id: 'classForm.classLocationHeader',
        defaultMessage: 'Class location',
    },

    // FIELD LABELS
    selectedLabel: {
        id: 'classForm.selectedLabel',
        defaultMessage: 'Selected',
    },

    courseTemplateLabel: {
        id: 'classForm.courseTemplateLabel',
        defaultMessage: 'Course template',
    },
    courseTemplatePlaceholder: {
        id: 'classForm.courseTemplatePlaceholder',
        defaultMessage: 'Select course template',
    },
    courseTemplateEmptyLabel: {
        id: 'classForm.courseTemplateEmptyLabel',
        defaultMessage: 'No course templates available',
    },

    courseVersionLabel: {
        id: 'classForm.courseVersionLabel',
        defaultMessage: 'Course version',
    },
    courseVersionPlaceholder: {
        id: 'classForm.courseVersionPlaceholder',
        defaultMessage: 'Select course version',
    },
    courseVersionEmptyLabel: {
        id: 'classForm.courseVersionEmptyLabel',
        defaultMessage: 'No course versions available',
    },
    courseVersionContentLabel: {
        id: 'classForm.courseVersionContentLabel',
        defaultMessage: 'Content Version',
    },

    courseLanguageLabel: {
        id: 'classForm.courseLanguageLabel',
        defaultMessage: 'Course language',
    },
    courseLanguagePlaceholder: {
        id: 'classForm.courseLanguagePlaceholder',
        defaultMessage: 'Select course language',
    },
    courseLanguageEmptyLabel: {
        id: 'classForm.courseLanguageEmptyLabel',
        defaultMessage: 'No course languages available',
    },

    singleCodeLabel: {
        id: 'classForm.singleCodeLabel',
        defaultMessage: 'Create single code',
    },
    multiUseCodeLabel: {
        id: 'classForm.multiUseCodeLabel',
        defaultMessage: 'Multi-use code',
    },
    uniqueCodeLabel: {
        id: 'classForm.uniqueCodeLabel',
        defaultMessage: 'Create unique codes',
    },
    createdSingleCodeLabel: {
        id: 'classForm.createdSingleCodeLabel',
        defaultMessage: 'Single code created',
    },
    createdUniqueCodeLabel: {
        id: 'classForm.createdUniqueCodeLabel',
        defaultMessage: 'Unique codes created',
    },
    noAccessCodesLabel: {
        id: 'classForm.noAccessCodesLable',
        defaultMessage: 'No access codes created',
    },

    numberOfStudentsLabel: {
        id: 'classForm.numberOfStudentsLabel',
        defaultMessage: 'Number of students',
    },
    numberOfStudentsHint: {
        id: 'classForm.numberOfStudentsHint',
        defaultMessage: 'Must be between 1 and 300',
    },

    startDateLabel: {
        id: 'classForm.startDateLabel',
        defaultMessage: 'Start date',
    },
    endDateLabel: {
        id: 'classForm.endDateLabel',
        defaultMessage: 'End date',
    },
    todayLabel: {
        id: 'classForm.todayLabel',
        defaultMessage: 'Today',
    },
    nextMonthLabel: {
        id: 'classForm.nextMonthLabel',
        defaultMessage: 'Next month',
    },
    previousMonthLabel: {
        id: 'classForm.previousMonthLabel',
        defaultMessage: 'Previous month',
    },

    startTimeLabel: {
        id: 'classForm.startTimeLabel',
        defaultMessage: 'Start time',
    },
    endTimeLabel: {
        id: 'classForm.endTimeLabel',
        defaultMessage: 'End time',
    },
    timeZoneLabel: {
        id: 'classForm.timeZoneLabel',
        defaultMessage: 'Time zone',
    },

    trainingProviderLabel: {
        id: 'classForm.trainingProviderLabel',
        defaultMessage: 'Training provider',
    },
    instructorLabel: {
        id: 'classForm.instructorLabel',
        defaultMessage: 'Instructor',
    },
    instructorPlaceholder: {
        id: 'classForm.instructorPlaceholder',
        defaultMessage: 'Assign instructor',
    },
    instructorInputPlaceholder: {
        id: 'classForm.instructorInputPlaceholder',
        defaultMessage: 'example@email.com',
    },

    virtualClassUrlLabel: {
        id: 'classForm.virtualClassUrlLabel',
        defaultMessage: 'Virtual classroom URL',
    },
    virtualDescription: {
        id: 'classForm.virtualDescription',
        defaultMessage: 'Enter URL of Webex or GoToTraining for virtual class.',
    },
    studentViewUrlLabel: {
        id: 'classForm.studentViewUrlLabel',
        defaultMessage: 'Student view URL',
    },

    addressLine1Label: {
        id: 'classForm.addressLine1Label',
        defaultMessage: 'Street address line 1',
    },
    addressLine2Label: {
        id: 'classForm.addressLine2Label',
        defaultMessage: 'Street address line 2',
    },
    cityLabel: {
        id: 'classForm.cityLabel',
        defaultMessage: 'City',
    },
    stateLabel: {
        id: 'classForm.stateLabel',
        defaultMessage: 'State / province / territory',
    },
    postalCodeLabel: {
        id: 'classForm.postalCodeLabel',
        defaultMessage: 'Postal / zip code',
    },
    countryLabel: {
        id: 'classForm.countryLabel',
        defaultMessage: 'Country',
    },
    countryPlaceholder: {
        id: 'classForm.countryPlaceholder',
        defaultMessage: 'Select country',
    },

    loadingField: {
        id: 'classForm.loadingField',
        defaultMessage: 'Loading...',
    },

    // field validation errors
    courseIdRequired: {
        id: 'classForm.courseIdRequired',
        defaultMessage: 'Course template is required',
    },
    courseIdInvalid: {
        id: 'classForm.courseIdInvalid',
        defaultMessage: 'Course template is invalid',
    },
    courseVersionIdRequired: {
        id: 'classForm.courseVersionIdRequired',
        defaultMessage: 'Course version is required',
    },
    courseVersionIdInvalid: {
        id: 'classForm.courseVersionIdInvalid',
        defaultMessage: 'Course version is invalid',
    },
    courseVersionIdChangeInvalid: {
        id: 'classForm.courseVersionIdChangeInvalid',
        defaultMessage: "Course's major and minor versions can't be changed",
    },
    langLocaleRequired: {
        id: 'classForm.langLocaleRequired',
        defaultMessage: 'Language is required',
    },
    langLocaleInvalid: {
        id: 'classForm.langLocaleInvalid',
        defaultMessage: 'Language is invalid',
    },
    langLocaleChangeInvalid: {
        id: 'classForm.langLocaleChangeInvalid',
        defaultMessage: "Language can't be changed after class is created",
    },
    classCapacityRequired: {
        id: 'classForm.classCapacityRequired',
        defaultMessage: 'Number of students is required',
    },
    classCapacityNumLimit: {
        id: 'classForm.classCapacityNumLimit',
        defaultMessage: 'Number of students must be between 1 and 300',
    },
    classCapacityDecreaseInvalid: {
        id: 'classForm.classCapacityDecreaseInvalid',
        defaultMessage: "Class size can't be decreased",
    },
    classCapacityIncreaseInvalid: {
        id: 'classForm.classCapacityIncreaseInvalid',
        defaultMessage: "Class size can't be increased due to insufficient licenses",
    },
    classCapacityInvalid: {
        id: 'classForm.classCapacityInvalid',
        defaultMessage: 'Number of students is not valid',
    },
    endDateEndDatePassed: {
        id: 'classForm.endDateEndDatePassed',
        defaultMessage: "End date can't be in the past",
    },
    startDateRequired: {
        id: 'classForm.startDateRequired',
        defaultMessage: 'Start date is required',
    },
    endDateRequired: {
        id: 'classForm.endDateRequired',
        defaultMessage: 'End date is required',
    },
    endDateEndBeforeStart: {
        id: 'classForm.endDateEndBeforeStart',
        defaultMessage: "End date can't be before start date",
    },
    endDateDurationExceeded: {
        id: 'classForm.endDateDurationExceeded',
        defaultMessage: "End date can't be more than 5 weeks after start date",
    },
    startTimeRequired: {
        id: 'classForm.startTimeRequired',
        defaultMessage: 'Start time is required',
    },
    endTimeRequired: {
        id: 'classForm.endTimeRequired',
        defaultMessage: 'End time is required',
    },
    startEndTimeInvalid: {
        id: 'classForm.startEndTimeInvalid',
        defaultMessage: "End time can't be before start time",
    },
    timezoneRequired: {
        id: 'classForm.timezoneRequired',
        defaultMessage: 'Timezone is required',
    },
    timezoneInvalid: {
        id: 'classForm.timezoneInvalid',
        defaultMessage: 'Timezone is invalid',
    },
    virtualUrlRequired: {
        id: 'classForm.virtualUrlRequired',
        defaultMessage: 'Virtual url is required for virtual classroom',
    },
    virtualUrlPattern: {
        id: 'classForm.virtualUrlPattern',
        defaultMessage: 'Virtual url must be a valid url',
    },
    virtualUrlInvalid: {
        id: 'classForm.virtualUrlInvalid',
        defaultMessage: 'Virtual url is invalid',
    },
    virtualUrlLength: {
        id: 'classForm.virtualUrlLength',
        defaultMessage: 'Virtual url cannot exceed 255 characters',
    },
    addressLine1Required: {
        id: 'classForm.addressLine1Required',
        defaultMessage: 'Address is required for in-person classroom',
    },
    addressLine1Length: {
        id: 'classForm.addressLine1Length',
        defaultMessage: 'Address line 1 cannot exceed 100 characters',
    },
    addressLine1Invalid: {
        id: 'classForm.addressLine1Invalid',
        defaultMessage: 'Address is invalid',
    },
    addressLine2Length: {
        id: 'classForm.addressLine2Length',
        defaultMessage: 'Address line 2 cannot exceed 100 characters',
    },
    addressLine2Invalid: {
        id: 'classForm.addressLine2Invalid',
        defaultMessage: 'Address line 2 is invalid',
    },
    cityRequired: {
        id: 'classForm.cityRequired',
        defaultMessage: 'City is required for in-person classroom',
    },
    cityLength: {
        id: 'classForm.cityLength',
        defaultMessage: 'City cannot exceed 60 characters',
    },
    cityInvalid: {
        id: 'classForm.cityInvalid',
        defaultMessage: 'City is invalid',
    },
    stateRequired: {
        id: 'classForm.stateRequired',
        defaultMessage: 'State is required for in-person classroom',
    },
    stateLength: {
        id: 'classForm.stateLength',
        defaultMessage: 'State cannot exceed 60 characters',
    },
    stateInvalid: {
        id: 'classForm.stateInvalid',
        defaultMessage: 'State is invalid',
    },
    postalCodeRequired: {
        id: 'classForm.postalCodeRequired',
        defaultMessage: 'Postal / zip code is required for in-person classroom',
    },
    postalCodeLength: {
        id: 'classForm.postalCodeLength',
        defaultMessage: 'Postal / zip code cannot exceed 16 characters',
    },
    postalCodeInvalid: {
        id: 'classForm.postalCodeInvalid',
        defaultMessage: 'Postal / zip code is invalid',
    },
    countryRequired: {
        id: 'classForm.countryRequired',
        defaultMessage: 'Country is required for in-person classroom',
    },
    countryLength: {
        id: 'classForm.countryLength',
        defaultMessage: 'Country must be between 2 and 60 characters',
    },
    countryInvalid: {
        id: 'classForm.countryInvalid',
        defaultMessage: 'Country is invalid',
    },
    instructorsPattern: {
        id: 'classForm.instructorsPattern',
        defaultMessage: 'Instructor email is invalid',
    },
    instructorsRequired: {
        id: 'classForm.instructorsRequired',
        defaultMessage: 'Instructor email is required',
    },
    instructorsInvalidInclusion: {
        id: 'classForm.instructorsInvalid',
        defaultMessage: "Training coordinators can't be class instructors",
    },

    // Roster resize modal
    rosterResizeIncreaseHeader: {
        id: 'classForm.rosterResizeIncreaseHeader',
        defaultMessage: 'Increasing class size',
    },
    rosterResizeIncreaseContent: {
        id: 'classForm.rosterResizeIncreaseContent',
        defaultMessage:
            'Increasing the class size will reserve an additional {additionalNum} Student eKits from your virtual stock.',
    },
    rosterResizeIncreaseErrorHeader: {
        id: 'classForm.rosterResizeIncreaseErrorHeader',
        defaultMessage: 'Not enough eKits available',
    },
    rosterResizeIncreaseErrorContent: {
        id: 'classForm.rosterResizeIncreaseErrorContent',
        defaultMessage:
            "Class size can't be increased because you don't have enough student eKits available. Visit Gilmore storefront to buy more.",
    },
    rosterResizeDecreaseErrorContent: {
        id: 'classForm.rosterResizeDecreaseErrorContent',
        defaultMessage: "Can't decrease class size",
    },
    maximumInstructorNote: {
        id: 'classForm.maximumInstructorNote',
        defaultMessage: 'A class can have up to 5 instructors.',
    },
    invalidInclusionErrorBody: {
        id: 'classForm.invalidInclusionErrorBody',
        defaultMessage:
            "As a training coordinator, you can't be added as the class' instructor. Please choose another instructor.",
    },
    expiredClassHeader: {
        id: 'classForm.expiredClassHeader',
        defaultMessage: 'Class has expired.',
    },
    expiredClassBody: {
        id: 'classForm.expiredClassBody',
        defaultMessage: 'An expired class cannot be edited, please create a new class.',
    },
});

export const getErrorMessage = (property, code) => {
    const message = messages[`${property}${code}`];
    if (!message) {
        // report that we dont have a translation for that property/code combination
        sendClientLog({
            type: 'Translations',
            attributes: { [property]: code },
            metrics: { missingValidationCodeTranslation: [1, 'Count'] },
        });
        return messages[`${property}Invalid`];
    }
    return message;
};
