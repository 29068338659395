import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Table, TableSorting, TablePagination } from '@amzn/awsui-components-react';
import { getColumnLabelBase } from './AccessCodeTable.utils';
import { copyToClipboard } from 'utils/helpers';
import messages from './AccessCodeTable.messages';
import './AccessCodeTable.css';

const COLUMN_ID = {
    accessCode: 'accessCode',
    accessCodeCapacityUsed: 'accessCodeCapacityUsed',
};

const buildColumnDefinitions = (formatMessage, getColumnLabel, studentUrl, singleAccessCode) => [
    {
        id: COLUMN_ID.accessCode,
        header: (
            <div>
                <span>
                    {formatMessage(
                        singleAccessCode
                            ? messages.multiUseCodeLabel
                            : messages.singleAccessCodeLabel
                    )}
                </span>
                &nbsp;&ndash;&nbsp;
                <span className="access-code-description">
                    {formatMessage(messages.accessCodeDescription)}
                </span>
            </div>
        ),
        cell: ({ accessCode, accessCodeCapacityUsed, accessCodeCapacity }) => {
            if (accessCodeCapacityUsed >= accessCodeCapacity) return accessCode;
            const studentAccessUrl = `${studentUrl}?accessCode=${encodeURIComponent(accessCode)}`;
            return (
                <div className="access-code-cell">
                    <a
                        className="access-code-url"
                        href={studentAccessUrl}
                        aria-label={formatMessage(messages.studentAccessUrlLabel)}
                    >
                        {studentAccessUrl}
                    </a>
                    <CopyButton>{studentAccessUrl}</CopyButton>
                </div>
            );
        },
    },
    {
        id: COLUMN_ID.accessCodeCapacityUsed,
        header: formatMessage(messages.useCodeLabel),
        label: singleAccessCode
            ? undefined
            : sortState =>
                  getColumnLabel(
                      sortState,
                      COLUMN_ID.accessCodeCapacityUsed,
                      formatMessage(messages.useCodeLabel)
                  ),
        cell: ({ accessCodeCapacityUsed, accessCodeCapacity }) =>
            `${accessCodeCapacityUsed}/${accessCodeCapacity}`,
    },
];

const COPY_BUTTON_TIMEOUT_MS = 3000;

const CopyButton = ({ children }) => {
    const { formatMessage } = useIntl();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        let id;
        if (copied) id = setTimeout(() => setCopied(false), COPY_BUTTON_TIMEOUT_MS);
        return () => clearTimeout(id);
    }, [copied]);

    return (
        <Button
            variant="normal"
            wrapText={false}
            label={formatMessage(messages.copyCodeLabel)}
            onClick={() => {
                copyToClipboard({ globals: window, value: children });
                setCopied(true);
            }}
        >
            {copied ? formatMessage(messages.codeCopied) : formatMessage(messages.copyCodeLabel)}
        </Button>
    );
};

const AccessCodeTable = ({ accessCodes, studentUrl }) => {
    const { formatMessage } = useIntl();
    const getColumnLabel = getColumnLabelBase(
        formatMessage(messages.sortedAscending),
        formatMessage(messages.sortedDescending)
    );

    if (!accessCodes || accessCodes.length === 0) return null;

    const singleAccessCode = accessCodes.length === 1;

    const columnDefinitions = buildColumnDefinitions(
        formatMessage,
        getColumnLabel,
        studentUrl,
        singleAccessCode
    );

    return (
        <Table
            className="access-codes-table"
            header={<h2>{formatMessage(messages.accessCodeHeader)}</h2>}
            items={accessCodes}
            features={singleAccessCode ? [] : ['sorting', 'pagination']}
            columnDefinitions={columnDefinitions}
        >
            {singleAccessCode ? null : (
                <TableSorting
                    sortingColumn={COLUMN_ID.accessCodeCapacityUsed}
                    sortingAscending
                    sortableColumns={[
                        { id: COLUMN_ID.accessCodeCapacityUsed, field: 'accessCodeCapacityUsed' },
                    ]}
                />
            )}

            {singleAccessCode ? null : <TablePagination pageSize={5} />}
        </Table>
    );
};

export default AccessCodeTable;
