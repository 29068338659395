import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
    Form,
    Button,
    ColumnLayout,
    FormField,
    Input,
    Select,
    DatePicker,
    RadioGroup,
    FormSection,
    TimeInput,
    Modal,
} from '@amzn/awsui-components-react';
import _get from 'lodash/get';

import { TimeZoneSelect, ExpiredClassModal } from 'components';
import { paths } from 'utils/paths';
import { getStudentURL } from 'utils/helpers';
import { useFlags } from 'utils/flags';
import { useUserInfo } from 'utils/userInfo';
import {
    getEpochDate,
    isInvalidDate,
    isInvalidTime,
    timeHasPassed,
    isInvalidDateDiff,
    isDateTimeInPast,
} from 'utils/timestamp-utils';
import { getCourses, getAllCourseVersions, getCourseVersionDetails } from 'modules';
import { useProvider } from 'data/ProviderContext';
import { messages } from './ClassForm.messages';
import {
    LOCATION_TYPES,
    useLocationTypeLabel,
    handleFormValueChange,
    mapCoursesToSelectObject,
    mapCoursesVersionsToSelectObject,
    mapLocalesToSelectObject,
    prepareCountriesFoSelect,
    usePrevious,
    transformIncomingDataToFormData,
    transformIncomingDataToDateTimeData,
    initialFieldsInvalidState,
    validateData,
    MAX_CLASS_CAPACITY,
    prepareFormData,
    validationErrorCodeMap,
    putUserEmailFirstInArray,
} from './ClassForm.utils';
import { FieldErrors, ErrorMessage } from './FieldErrors';
import './ClassForm.css';
import InstructorAssign from '../instructorAssign/InstructorAssign';
import CapacityChangeModal, * as STATUSES from '../capacityChangeModal/CapacityChangeModal';

const ClassForm = ({ onSubmit, onError, classroomId, data }) => {
    const { formatMessage, locale } = useIntl();
    const history = useHistory();
    const isNewClass = classroomId === undefined;
    const countryOptions = useMemo(() => prepareCountriesFoSelect(formatMessage), [formatMessage]);

    const [formProcessing, setFormProcessing] = useState(false);

    const flags = useFlags();
    const userInfo = useUserInfo();
    const [courses, setCourses] = useState([]);
    const [courseVersions, setCourseVersions] = useState([]);
    const [courseVersionsLoading, setCourseVersionsLoading] = useState(false);
    const [courseVersionDetails, courseVersionDetailsSet] = useState([]);
    const [courseVersionDetailsLoading, courseVersionDetailsLoadingSet] = useState(false);
    const [classData, setClassData] = useState({
        courseId: '',
        courseVersionId: '',
        langLocale: '',
        locationType: LOCATION_TYPES.virtual,
        classCapacity: '1',
        virtualUrl: '',
        timezone: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        instructors: [],
        ...transformIncomingDataToFormData(data),
    });
    const [classCapacityChangeStatus, classCapacityChangeStatusSet] = useState(STATUSES.NO_NEED);

    const providerFromStorage = useProvider();
    let providerName = _get(data, 'trainingProvider'); // provider name when editing class
    if (isNewClass) {
        providerName = _get(providerFromStorage, 'name');
    }
    // set default valid state of all fields to true
    const [fieldsInvalid, setFieldsInvalid] = useState(initialFieldsInvalidState);

    const thereAreInstructorErrors = fieldsInvalid?.instructors || fieldsInvalid?.users;
    const showInvalidInstructorModal =
        !!thereAreInstructorErrors?.length &&
        thereAreInstructorErrors.includes(validationErrorCodeMap.invalidInclusion);

    // usePrevious helps to determine if there was a change between actual
    // values for courseId and courseVersionId which inform whether to reset
    // the courseVersionId and langLocale inputs respectively. This matters
    // when a form is updated with data returned from an existing class because
    // it is responding to changes and would clear out any current choice when
    // populating the form state with the provided data
    const prevCourseId = usePrevious(classData.courseId);
    const prevCourseVersionId = usePrevious(classData.courseVersionId);

    const [dateTimeData, setDateTimeData] = useState(transformIncomingDataToDateTimeData(data));
    const virtualLocationLabel = useLocationTypeLabel(LOCATION_TYPES.virtual);
    const physicalLocationLabel = useLocationTypeLabel(LOCATION_TYPES.physical);

    const hasClassStarted = isNewClass ? false : timeHasPassed(data.startsOn);
    const hasClassExpired = !isNewClass && timeHasPassed(data.endsOn);
    const endDateInPast =
        !hasClassStarted &&
        isDateTimeInPast(dateTimeData.endDate, dateTimeData.endTime, classData.timezone);
    const endDateBeforeStartDate = isInvalidDate(dateTimeData.startDate, dateTimeData.endDate);
    // verify end date is within 5 weeks of start date
    const endDateTooFar = isInvalidDateDiff(dateTimeData.startDate, dateTimeData.endDate);
    const endTimeBeforeStartTime = isInvalidTime(dateTimeData);
    const rosterSizeDifference = parseInt(classData?.classCapacity) - (data?.classCapacity ?? 0);

    useEffect(() => {
        if (userInfo.isLoading || !userInfo.email) return;

        if (classData.instructors.length === 0) {
            const initialValue = !userInfo.userIsTrainingCoordinator ? userInfo.email : '';
            handleFormValueChange({
                value: [initialValue],
                setData: setClassData,
                keyPath: 'instructors',
            });
        } else if (
            !userInfo.userIsTrainingCoordinator &&
            classData.instructors[0] !== userInfo.email
        ) {
            const initialValue = putUserEmailFirstInArray(classData.instructors, userInfo.email);
            handleFormValueChange({
                value: initialValue,
                setData: setClassData,
                keyPath: 'instructors',
            });
        }
    }, [userInfo, classData.instructors]);

    // get courses data
    useEffect(() => {
        getCourses().then(setCourses);
    }, []);

    // reset any selected course version id when course id changes and get
    // corresponding course versions
    useEffect(() => {
        if (prevCourseId) {
            handleFormValueChange({
                value: '',
                setData: setClassData,
                keyPath: 'courseVersionId',
            });
        }
        setCourseVersionsLoading(true);
        getAllCourseVersions(classData.courseId)
            .then(setCourseVersions)
            .finally(() => setCourseVersionsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classData.courseId]);

    // reset any selected langLocale when course version id changes and get
    // corresponding locales
    useEffect(() => {
        if (prevCourseVersionId) {
            handleFormValueChange({
                value: '',
                setData: setClassData,
                keyPath: 'langLocale',
            });
        }
        courseVersionDetailsLoadingSet(true);
        getCourseVersionDetails(classData.courseVersionId)
            .then(courseVersionDetailsSet)
            .finally(() => courseVersionDetailsLoadingSet(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classData.courseVersionId]);

    const handleKeyboardSubmit = e => {
        if (e.detail.key === 'Enter') handleFormSubmit(e);
    };

    useEffect(() => {
        if (classCapacityChangeStatus === STATUSES.CLEARED) {
            handleFormSubmit(new Event('submit', { cancelable: true }));
        } else if (classCapacityChangeStatus === STATUSES.CANCELLED) {
            setFormProcessing(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classCapacityChangeStatus]);

    const handleFormSubmit = e => {
        e.preventDefault();
        // Disable form action buttons to provide user feedback
        setFormProcessing(true);
        setFieldsInvalid(initialFieldsInvalidState);

        // Skip instructor validation if no instructors were added by a Training Coordinator
        if (
            userInfo.userIsTrainingCoordinator &&
            classData.instructors.every(email => email === '')
        ) {
            classData.instructors = [];
        }

        const { dataValidity, allValid } = validateData(
            { ...classData, ...dateTimeData },
            userInfo
        );
        // extra validators that dates are before/after each other
        const dateTimesAreValid = [
            endDateInPast,
            endDateBeforeStartDate,
            endTimeBeforeStartTime,
            endDateTooFar,
        ].every(val => !val);

        // if any values are false
        if (!allValid || !dateTimesAreValid) {
            setFieldsInvalid(dataValidity);
            setFormProcessing(false);
            return;
        }

        // show error modal if trying to decrease roster size (not supported yet)
        const needToShowCapacityDecreaseError = !isNewClass && rosterSizeDifference < 0;

        // show warning modal if trying to increase roster size
        const needToStopForCapacityIncreaseModal =
            !isNewClass &&
            rosterSizeDifference > 0 &&
            classCapacityChangeStatus !== STATUSES.CLEARED;

        if (needToShowCapacityDecreaseError) {
            classCapacityChangeStatusSet(STATUSES.DECREASE_ERROR);
            setFormProcessing(false);
            return;
        }

        if (needToStopForCapacityIncreaseModal && providerFromStorage?.type === 'ATP') {
            classCapacityChangeStatusSet(STATUSES.NEED_TO_CLEAR);
            setFormProcessing(false);
            return;
        }

        const mutationVars = prepareFormData(
            {
                ...classData,
                ...getEpochDate(dateTimeData, classData.timezone),
                classroomId,
                providerArn: _get(providerFromStorage, 'arn'),
            },
            { flags }
        );

        return onSubmit(mutationVars)
            .then(data => {
                setFormProcessing(false);
                if (data) {
                    history.push(paths.classDetailPage(data.classroomId));
                }
            })
            .catch(err => {
                setFormProcessing(false);
                try {
                    const { errors } = JSON.parse(err);
                    const invalidFields = errors.reduce(
                        (acc, { property, errorCodes }) => ({
                            ...acc,
                            [property]: errorCodes.map(({ code }) =>
                                code.replace('PropertyError:', '')
                            ),
                        }),
                        {}
                    );
                    if (
                        Array.isArray(invalidFields?.classCapacity) &&
                        invalidFields?.classCapacity.includes('IncreaseInvalid')
                    ) {
                        classCapacityChangeStatusSet(STATUSES.LICENSE_ERROR);
                    }
                    setFieldsInvalid(invalidFields);
                } catch (anotherError) {
                    // the original error was not because of backend validation, escalate
                    console.log('original error', err);
                    // weird behavior from graphql where the caught error is undefined
                    onError(err || 'graphql error');
                }
            });
    };

    const FormActions = () => (
        <Fragment>
            {!isNewClass ? (
                <Button
                    variant="link"
                    disabled={formProcessing}
                    onClick={() => history.push(paths.classDetailPage(classroomId))}
                >
                    {formatMessage(messages.cancelButtonText)}
                </Button>
            ) : null}
            <Button
                variant="primary"
                loading={formProcessing}
                disabled={hasClassExpired}
                label={formatMessage(messages.saveButtonLabel)}
                onClick={handleFormSubmit}
            >
                {formatMessage(messages.saveButtonText)}
            </Button>
        </Fragment>
    );

    return (
        <div>
            {providerName ? (
                <h1 className="awsui-text-large awsui-util-pb-l">{providerName}</h1>
            ) : null}
            <div className="awsui-util-action-stripe-large">
                <div className="awsui-util-action-stripe-title">
                    <h2>
                        {isNewClass
                            ? formatMessage(messages.addPageTitle)
                            : formatMessage(messages.editPageTitle)}
                    </h2>
                </div>
                <div className="awsui-util-action-stripe-group">
                    <FormActions />
                </div>
            </div>
            <Form actions={<FormActions />}>
                <FormSection header={formatMessage(messages.courseInfoHeader)}>
                    <ColumnLayout>
                        <div data-awsui-column-layout-root="true">
                            <FormField stretch label={formatMessage(messages.courseTemplateLabel)}>
                                <Select
                                    ariaRequired
                                    invalid={!!fieldsInvalid.courseId}
                                    disabled={!isNewClass}
                                    selectedId={classData.courseId}
                                    label={formatMessage(messages.courseTemplatePlaceholder)}
                                    options={courses
                                        .map(mapCoursesToSelectObject)
                                        .sort((a, b) =>
                                            a.label.toUpperCase() < b.label.toUpperCase() ? -1 : +1
                                        )}
                                    empty={formatMessage(messages.courseTemplateEmptyLabel)}
                                    placeholder={formatMessage(messages.courseTemplatePlaceholder)}
                                    selectedLabel={formatMessage(messages.selectedLabel)}
                                    onChange={e => {
                                        handleFormValueChange({
                                            value: e.detail.selectedId,
                                            setData: setClassData,
                                            keyPath: 'courseId',
                                        });
                                    }}
                                ></Select>
                                <FieldErrors fieldsInvalid={fieldsInvalid} property="courseId" />
                            </FormField>
                            <FormField stretch label={formatMessage(messages.courseVersionLabel)}>
                                <Select
                                    ariaRequired
                                    invalid={!!fieldsInvalid.courseVersionId}
                                    selectedId={classData.courseVersionId}
                                    label={formatMessage(messages.courseVersionPlaceholder)}
                                    options={courseVersions.map(
                                        mapCoursesVersionsToSelectObject(formatMessage)
                                    )}
                                    empty={formatMessage(
                                        courseVersionsLoading
                                            ? messages.loadingField
                                            : messages.courseVersionEmptyLabel
                                    )}
                                    placeholder={formatMessage(messages.courseVersionPlaceholder)}
                                    selectedLabel={formatMessage(messages.selectedLabel)}
                                    onChange={e =>
                                        handleFormValueChange({
                                            value: e.detail.selectedId,
                                            setData: setClassData,
                                            keyPath: 'courseVersionId',
                                        })
                                    }
                                ></Select>
                                <FieldErrors
                                    fieldsInvalid={fieldsInvalid}
                                    property="courseVersionId"
                                />
                            </FormField>
                            <FormField
                                stretch
                                label={formatMessage(messages.courseVersionContentLabel)}
                            >
                                <Input
                                    className="class-form__semantic-version-input"
                                    readonly
                                    disabled
                                    value={_get(courseVersionDetails, 'contentVersion', '')}
                                />
                            </FormField>
                            <FormField stretch label={formatMessage(messages.courseLanguageLabel)}>
                                <Select
                                    ariaRequired
                                    invalid={!!fieldsInvalid.langLocale}
                                    selectedId={classData.langLocale}
                                    label={formatMessage(messages.courseLanguagePlaceholder)}
                                    options={_get(courseVersionDetails, 'supportedLocales', []).map(
                                        mapLocalesToSelectObject
                                    )}
                                    empty={formatMessage(
                                        courseVersionDetailsLoading
                                            ? messages.loadingField
                                            : messages.courseLanguageEmptyLabel
                                    )}
                                    placeholder={formatMessage(messages.courseLanguagePlaceholder)}
                                    selectedLabel={formatMessage(messages.selectedLabel)}
                                    onChange={e =>
                                        handleFormValueChange({
                                            value: e.detail.selectedId,
                                            setData: setClassData,
                                            keyPath: 'langLocale',
                                        })
                                    }
                                ></Select>
                                <FieldErrors fieldsInvalid={fieldsInvalid} property="langLocale" />
                            </FormField>
                        </div>
                    </ColumnLayout>
                </FormSection>

                <InstructorAssign
                    providerName={providerName}
                    instructors={classData.instructors}
                    fieldsInvalid={fieldsInvalid}
                    instructorsSet={newValue => {
                        handleFormValueChange({
                            value: newValue,
                            setData: setClassData,
                            keyPath: 'instructors',
                        });
                    }}
                />

                <FormSection
                    header={formatMessage(messages.codeInfoHeader)}
                    data-testid="class-capacity__form-section"
                >
                    <ColumnLayout>
                        <div data-awsui-column-layout-root="true">
                            <p>{formatMessage(messages.multiUseCodeLabel)}</p>
                            <FormField
                                stretch
                                description={formatMessage(messages.numberOfStudentsHint)}
                                label={formatMessage(messages.numberOfStudentsLabel)}
                            >
                                <Input
                                    ariaRequired
                                    invalid={
                                        !!fieldsInvalid.classCapacity || rosterSizeDifference < 0
                                    }
                                    disabled={!isNewClass && !flags.classroomResize}
                                    value={classData.classCapacity}
                                    type="number"
                                    onChange={e => {
                                        handleFormValueChange({
                                            value: Math.min(
                                                MAX_CLASS_CAPACITY,
                                                Math.max(e.detail.value, 1)
                                            ).toString(),
                                            setData: setClassData,
                                            keyPath: 'classCapacity',
                                        });
                                        const accessCode =
                                            e.detail.value !== data?.classCapacity
                                                ? data?.accessCodes[0]?.accessCode
                                                : '';
                                        handleFormValueChange({
                                            value: accessCode,
                                            keyPath: 'accessCode',
                                            setData: setClassData,
                                        });
                                    }}
                                />
                                <FieldErrors
                                    fieldsInvalid={
                                        !fieldsInvalid.classCapacity && rosterSizeDifference < 0
                                            ? { classCapacity: ['DecreaseInvalid'] }
                                            : {}
                                    }
                                    property="classCapacity"
                                />
                                <FieldErrors
                                    fieldsInvalid={fieldsInvalid}
                                    property="classCapacity"
                                />
                            </FormField>
                        </div>
                    </ColumnLayout>
                </FormSection>

                <FormSection header={formatMessage(messages.classTimeHeader)}>
                    <div className="awsui-grid">
                        <div className="awsui-row">
                            <div className="col-12">
                                <FormField stretch label={formatMessage(messages.timeZoneLabel)}>
                                    <TimeZoneSelect
                                        invalid={!!fieldsInvalid.timezone || endDateInPast}
                                        disabled={hasClassStarted}
                                        selected={classData.timezone}
                                        onChange={e => {
                                            handleFormValueChange({
                                                value: e.detail.selectedId,
                                                setData: setClassData,
                                                keyPath: 'timezone',
                                            });
                                        }}
                                    />
                                    <FieldErrors
                                        fieldsInvalid={fieldsInvalid}
                                        property="timezone"
                                    />
                                </FormField>
                            </div>
                        </div>
                        <div className="awsui-row">
                            <div className="col-xxxs-12 col-xxs-6 col-xs-6 col-s-6">
                                <FormField stretch label={formatMessage(messages.startDateLabel)}>
                                    <DatePicker
                                        id="classForm__startDate"
                                        ariaRequired
                                        invalid={!!fieldsInvalid.startDate}
                                        disabled={hasClassStarted}
                                        locale={locale}
                                        value={dateTimeData.startDate}
                                        onChange={e => {
                                            handleFormValueChange({
                                                value: e.detail.value,
                                                setData: setDateTimeData,
                                                keyPath: 'startDate',
                                            });
                                        }}
                                        placeholder="YYYY/MM/DD"
                                        todayLabel={formatMessage(messages.todayLabel)}
                                        nextMonthLabel={formatMessage(messages.nextMonthLabel)}
                                        previousMonthLabel={formatMessage(
                                            messages.previousMonthLabel
                                        )}
                                    ></DatePicker>
                                    <FieldErrors
                                        fieldsInvalid={fieldsInvalid}
                                        property="startDate"
                                    />
                                </FormField>
                            </div>
                            <div className="col-xxxs-12 col-xxs-6 col-xs-6 col-s-6">
                                <FormField stretch label={formatMessage(messages.endDateLabel)}>
                                    <DatePicker
                                        id="classForm__endDate"
                                        ariaRequired
                                        invalid={
                                            !!fieldsInvalid.endDate ||
                                            endDateInPast ||
                                            endDateBeforeStartDate ||
                                            endDateTooFar
                                        }
                                        disabled={hasClassStarted}
                                        locale={locale}
                                        value={dateTimeData.endDate}
                                        onChange={e => {
                                            handleFormValueChange({
                                                value: e.detail.value,
                                                setData: setDateTimeData,
                                                keyPath: 'endDate',
                                            });
                                        }}
                                        placeholder="YYYY/MM/DD"
                                        todayLabel={formatMessage(messages.todayLabel)}
                                        nextMonthLabel={formatMessage(messages.nextMonthLabel)}
                                        previousMonthLabel={formatMessage(
                                            messages.previousMonthLabel
                                        )}
                                    ></DatePicker>
                                    <FieldErrors fieldsInvalid={fieldsInvalid} property="endDate" />
                                    {endDateInPast ? (
                                        <ErrorMessage>
                                            {formatMessage(messages.endDateEndDatePassed)}
                                        </ErrorMessage>
                                    ) : null}
                                    {endDateBeforeStartDate ? (
                                        <ErrorMessage>
                                            {formatMessage(messages.endDateEndBeforeStart)}
                                        </ErrorMessage>
                                    ) : null}
                                    {endDateTooFar ? (
                                        <ErrorMessage>
                                            {formatMessage(messages.endDateDurationExceeded)}
                                        </ErrorMessage>
                                    ) : null}
                                </FormField>
                            </div>
                        </div>
                        <div className="awsui-row">
                            <div className="col-xxxs-12 col-xxs-6 col-xs-6 col-s-6">
                                <FormField
                                    stretch
                                    id="FormField__startTime"
                                    controlId="classForm__startTime"
                                    label={formatMessage(messages.startTimeLabel)}
                                >
                                    <TimeInput
                                        format="hh:mm"
                                        placeholder="hh:mm"
                                        controlId="classForm__startTime"
                                        className="class-form__time-input"
                                        aria-labelledby="FormField__startTime"
                                        invalid={!!fieldsInvalid.startTime}
                                        disabled={hasClassStarted}
                                        onChange={e => {
                                            setDateTimeData(prevState => ({
                                                ...prevState,
                                                startTime: e.detail.value,
                                            }));
                                        }}
                                        ariaRequired
                                        value={dateTimeData.startTime}
                                    />
                                    <FieldErrors
                                        fieldsInvalid={fieldsInvalid}
                                        property="startTime"
                                    />
                                </FormField>
                            </div>
                            <div className="col-xxxs-12 col-xxs-6 col-xs-6 col-s-6">
                                <FormField
                                    stretch
                                    id="FormField__endTime"
                                    controlId="classForm__endTime"
                                    label={formatMessage(messages.endTimeLabel)}
                                >
                                    <TimeInput
                                        format="hh:mm"
                                        placeholder="hh:mm"
                                        controlId="classForm__endTime"
                                        className="class-form__time-input"
                                        aria-labelledby="FormField__endTime"
                                        invalid={
                                            !!fieldsInvalid.endTime ||
                                            endTimeBeforeStartTime ||
                                            endDateInPast
                                        }
                                        disabled={hasClassStarted}
                                        onChange={e => {
                                            setDateTimeData(prevState => ({
                                                ...prevState,
                                                endTime: e.detail.value,
                                            }));
                                        }}
                                        ariaRequired
                                        value={dateTimeData.endTime}
                                    />
                                    <FieldErrors fieldsInvalid={fieldsInvalid} property="endTime" />
                                    {endTimeBeforeStartTime ? (
                                        <ErrorMessage>
                                            {formatMessage(messages.startEndTimeInvalid)}
                                        </ErrorMessage>
                                    ) : null}
                                </FormField>
                            </div>
                        </div>
                    </div>
                </FormSection>

                <FormSection header={formatMessage(messages.classLocationHeader)}>
                    <ColumnLayout>
                        <div data-awsui-column-layout-root="true">
                            <FormField>
                                <RadioGroup
                                    value={classData.locationType}
                                    onChange={e => {
                                        handleFormValueChange({
                                            value: e.detail.value,
                                            setData: setClassData,
                                            keyPath: 'locationType',
                                        });
                                    }}
                                    items={[
                                        {
                                            value: LOCATION_TYPES.virtual,
                                            label: virtualLocationLabel,
                                        },
                                        {
                                            value: LOCATION_TYPES.physical,
                                            label: physicalLocationLabel,
                                        },
                                    ]}
                                    ariaRequired
                                    id="ClassForm__locationType"
                                ></RadioGroup>
                            </FormField>
                            {classData.locationType === LOCATION_TYPES.virtual ? (
                                <Fragment>
                                    <FormField
                                        stretch
                                        description={formatMessage(messages.virtualDescription)}
                                        label={formatMessage(messages.virtualClassUrlLabel)}
                                    >
                                        <div className="ClassForm__virtual">
                                            <Input
                                                ariaRequired
                                                value={classData.virtualUrl}
                                                invalid={!!fieldsInvalid.virtualUrl}
                                                onInput={e =>
                                                    handleFormValueChange({
                                                        value: e.detail.value,
                                                        setData: setClassData,
                                                        keyPath: 'virtualUrl',
                                                    })
                                                }
                                                id="ClassForm__virtualUrl"
                                                placeholder="https://aws.training"
                                                onKeypress={handleKeyboardSubmit}
                                            ></Input>
                                            <FieldErrors
                                                fieldsInvalid={fieldsInvalid}
                                                property="virtualUrl"
                                            />
                                        </div>
                                    </FormField>
                                    {!isNewClass ? (
                                        <FormField
                                            stretch
                                            label={formatMessage(messages.studentViewUrlLabel)}
                                        >
                                            <div className="ClassForm__virtual">
                                                <Input
                                                    ariaRequired
                                                    disabled
                                                    value={getStudentURL(classroomId)}
                                                ></Input>
                                            </div>
                                        </FormField>
                                    ) : null}
                                </Fragment>
                            ) : null}
                            {classData.locationType === LOCATION_TYPES.physical ? (
                                <Fragment>
                                    <ColumnLayout>
                                        <div data-awsui-column-layout-root="true">
                                            <FormField
                                                stretch
                                                label={formatMessage(messages.addressLine1Label)}
                                            >
                                                <Input
                                                    ariaRequired
                                                    id="addressSection__addressLine1"
                                                    invalid={!!fieldsInvalid.addressLine1}
                                                    value={classData.addressLine1}
                                                    onInput={e =>
                                                        handleFormValueChange({
                                                            value: e.detail.value,
                                                            setData: setClassData,
                                                            keyPath: 'addressLine1',
                                                        })
                                                    }
                                                />
                                                <FieldErrors
                                                    fieldsInvalid={fieldsInvalid}
                                                    property="addressLine1"
                                                />
                                            </FormField>
                                            <FormField
                                                stretch
                                                label={formatMessage(messages.addressLine2Label)}
                                            >
                                                <Input
                                                    id="addressSection__addressLine2"
                                                    value={classData.addressLine2}
                                                    onInput={e =>
                                                        handleFormValueChange({
                                                            value: e.detail.value,
                                                            setData: setClassData,
                                                            keyPath: 'addressLine2',
                                                        })
                                                    }
                                                />
                                                <FieldErrors
                                                    fieldsInvalid={fieldsInvalid}
                                                    property="addressLine2"
                                                />
                                            </FormField>
                                        </div>
                                    </ColumnLayout>
                                    <ColumnLayout columns={2}>
                                        <div data-awsui-column-layout-root="true">
                                            <FormField
                                                stretch
                                                label={formatMessage(messages.cityLabel)}
                                            >
                                                <Input
                                                    ariaRequired
                                                    id="addressSection__city"
                                                    value={classData.city}
                                                    invalid={!!fieldsInvalid.city}
                                                    onInput={e =>
                                                        handleFormValueChange({
                                                            value: e.detail.value,
                                                            setData: setClassData,
                                                            keyPath: 'city',
                                                        })
                                                    }
                                                />
                                                <FieldErrors
                                                    fieldsInvalid={fieldsInvalid}
                                                    property="city"
                                                />
                                            </FormField>
                                            <FormField
                                                stretch
                                                label={formatMessage(messages.stateLabel)}
                                            >
                                                <Input
                                                    ariaRequired
                                                    id="addressSection__state"
                                                    invalid={!!fieldsInvalid.state}
                                                    value={classData.state}
                                                    onInput={e =>
                                                        handleFormValueChange({
                                                            value: e.detail.value,
                                                            setData: setClassData,
                                                            keyPath: 'state',
                                                        })
                                                    }
                                                />
                                                <FieldErrors
                                                    fieldsInvalid={fieldsInvalid}
                                                    property="state"
                                                />
                                            </FormField>
                                            <FormField
                                                stretch
                                                label={formatMessage(messages.postalCodeLabel)}
                                            >
                                                <Input
                                                    ariaRequired
                                                    id="addressSection__postalCode"
                                                    invalid={!!fieldsInvalid.postalCode}
                                                    value={classData.postalCode}
                                                    onInput={e =>
                                                        handleFormValueChange({
                                                            value: e.detail.value,
                                                            setData: setClassData,
                                                            keyPath: 'postalCode',
                                                        })
                                                    }
                                                />
                                                <FieldErrors
                                                    fieldsInvalid={fieldsInvalid}
                                                    property="postalCode"
                                                />
                                            </FormField>
                                            <FormField
                                                stretch
                                                label={formatMessage(messages.countryLabel)}
                                            >
                                                <Select
                                                    ariaRequired
                                                    id="addressSection__country"
                                                    invalid={!!fieldsInvalid.country}
                                                    selectedId={classData.country}
                                                    filteringType="auto"
                                                    label={formatMessage(
                                                        messages.countryPlaceholder
                                                    )}
                                                    options={countryOptions}
                                                    placeholder={formatMessage(
                                                        messages.countryPlaceholder
                                                    )}
                                                    selectedLabel={formatMessage(
                                                        messages.selectedLabel
                                                    )}
                                                    onChange={e =>
                                                        handleFormValueChange({
                                                            value: e.detail.selectedId,
                                                            setData: setClassData,
                                                            keyPath: 'country',
                                                        })
                                                    }
                                                ></Select>

                                                <FieldErrors
                                                    fieldsInvalid={fieldsInvalid}
                                                    property="country"
                                                />
                                            </FormField>
                                        </div>
                                    </ColumnLayout>
                                </Fragment>
                            ) : null}
                        </div>
                    </ColumnLayout>
                </FormSection>
                <CapacityChangeModal
                    status={classCapacityChangeStatus}
                    statusSet={classCapacityChangeStatusSet}
                    newSize={parseInt(classData.classCapacity)}
                    originalSize={data?.classCapacity}
                />
                {showInvalidInstructorModal && (
                    <Modal
                        visible
                        header={formatMessage(messages.instructorsInvalidInclusion)}
                        closeLabel={formatMessage(messages.closeModalButtonLabel)}
                    >
                        {formatMessage(messages.invalidInclusionErrorBody)}
                    </Modal>
                )}

                <ExpiredClassModal
                    initiallyExpired={hasClassExpired}
                    expiredFromBackend={(fieldsInvalid.endsOn || []).includes('EndDatePassed')}
                />
            </Form>
        </div>
    );
};

export default ClassForm;
