import { API, Hub, Cache, graphqlOperation } from 'aws-amplify';
import crypto from 'crypto';
import { addMilliseconds } from 'date-fns';
import omit from 'lodash/omit';
import moment from 'moment-timezone';
import uniq from 'lodash/uniq';
import {
    getCourseArns,
    getCourseVersions,
    getCourseVersion,
    getProviderArns,
    getProvider,
    listUserRelationships,
    listClassroomRelationships,
    listUserClassrooms,
    listProviderClassrooms,
} from 'graphql/queries';
import {
    clientLog,
    createClassroomRelationships,
    updateClassroomV2,
    createClassroomV2,
} from 'graphql/mutations';
import { createLogMessage } from 'utils/createLogMessage';
import { SESSION_EXPIRED } from './session';
import { AppError, AUTH_ERROR, FETCH_ERROR_NAME, APIException } from 'utils/error';
import mockListClassrooms from 'data/classList.mock';

const HTTP_STATUS = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
};

const EXPIRATION_MS = 3600000; // 1 hour

function getHash(obj = {}, hashAlgorithm = 'sha256', encoding = 'hex') {
    const cryptoHash = crypto.createHash(hashAlgorithm);
    const stringifiedObj = JSON.stringify(obj);
    return cryptoHash.update(stringifiedObj).digest(encoding);
}

function handleHttpErrorResponse(response = {}, params = {}) {
    // This is what Amplify "throws" if the session is expired/invalid.
    if (response === 'No current user') {
        // Dispatch message to hub
        return Hub.dispatch(SESSION_EXPIRED);
    } else if (typeof response === 'string') {
        new AppError(`Unhandled amplify error: ${response}`, { type: AUTH_ERROR, ...params });
        return;
    }
    const { errors } = response;
    const { message = '', errorType, path = [] } = errors[0];
    if (message) {
        const statusCode = parseInt(message.split('::')[1]);
        switch (statusCode) {
            case HTTP_STATUS.UNAUTHORIZED:
                Hub.dispatch(SESSION_EXPIRED);
                break;
            case HTTP_STATUS.NOT_FOUND:
                // For now just a 404 page till we have UX direction
                window.location.replace('/error');
                return;
            case HTTP_STATUS.INTERNAL_SERVER_ERROR:
                new AppError(`operation ${path[0]} returned a 500`, {
                    status: statusCode,
                    type: FETCH_ERROR_NAME,
                    ...params,
                });
                return;
            case HTTP_STATUS.FORBIDDEN:
            case HTTP_STATUS.NOT_IMPLEMENTED:
            case HTTP_STATUS.CONFLICT:
                const toThrow = { statusCode, path: path[0] };
                throw new APIException(toThrow);
            default:
                throw errorType;
        }
    }
    throw errorType;
}

/**
 * Execute a GraphQL operation (query) and return results.
 * Options can be passed to enable response cache
 */
async function executeRequest({
    operation,
    params = {},
    onSubscribe = undefined,
    options = {
        useCache: false,
    },
}) {
    const newOperation = graphqlOperation(operation, params);
    const { useCache } = options;
    let results;
    try {
        if (onSubscribe) {
            results = await API.graphql(newOperation).subscribe({
                next: event => {
                    onSubscribe(event.value.data);
                },
            });
        } else {
            const operationKey = getHash(newOperation);
            if (Cache.getItem(operationKey)) {
                results = Cache.getItem(operationKey);
            } else {
                results = await API.graphql(newOperation);
                if (useCache) {
                    const expiration = addMilliseconds(new Date(), EXPIRATION_MS);
                    Cache.setItem(operationKey, results, {
                        expires: expiration.getTime(),
                    });
                }
            }
        }
    } catch (error) {
        handleHttpErrorResponse(error, params);
    }

    return results.data;
}

async function getCourses() {
    const { getCourseArns: payload } = await executeRequest({
        operation: getCourseArns,
    });
    return payload.courses;
}

async function getAllCourseVersions(courseId) {
    if (!courseId) return [];
    let courseVersions = [];
    let nextToken;
    do {
        const { getCourseVersions: payload } = await executeRequest({
            operation: getCourseVersions,
            params: {
                input: {
                    courseId,
                    nextToken,
                },
            },
        });
        courseVersions = courseVersions.concat(payload.courseVersions);
        nextToken = payload.nextToken;
    } while (nextToken);

    // sort course versions in descending createdOn order
    return courseVersions.sort((a, b) => b.createdOn - a.createdOn);
}

async function getCourseVersionDetails(courseVersionId) {
    if (!courseVersionId) return [];
    const { getCourseVersion: payload } = await executeRequest({
        operation: getCourseVersion,
        params: { courseVersionId },
    });
    return payload.courseVersion;
}

async function getProviders() {
    const {
        getProviderArns: { providers },
    } = await executeRequest({
        operation: getProviderArns,
    });
    return await Promise.all(providers.map(getProviderData));
}

async function getProviderData(providerArn) {
    const { getProvider: payload } = await executeRequest({
        operation: getProvider,
        params: { input: { providerArn } },
    });
    return payload.provider;
}

async function sendClientLog(payload) {
    return executeRequest({
        operation: clientLog,
        params: {
            input: {
                body: JSON.stringify(
                    createLogMessage({
                        payload,
                        globals: window,
                    })
                ),
            },
        },
    });
}

async function getUserRoles(providerArn) {
    if (!providerArn) {
        return [];
    }
    try {
        const { listUserRelationships: payload } = await executeRequest({
            operation: listUserRelationships,
            params: {
                input: { providerArn },
            },
        });
        return payload.relationships;
    } catch (err) {
        if (err?.statusCode === HTTP_STATUS.NOT_IMPLEMENTED) {
            return [];
        }
        throw err;
    }
}

const addInstructorsToClassroom = (instructors, classroomId, providerArn) =>
    executeRequest({
        operation: createClassroomRelationships,
        params: {
            input: {
                classroomId,
                providerArn,
                relationship: 'instructor',
                users: instructors.map(email => ({ email })),
            },
        },
    }).then(({ createClassroomRelationships }) => createClassroomRelationships);

const updateClassroom = async data => {
    const input = omit(data, 'instructors');
    const { updateClassroomV2: payload } = await executeRequest({
        operation: updateClassroomV2,
        params: { input },
    });
    return payload;
};

const createClassroom = async data => {
    const input = omit(data, 'instructors');
    const { createClassroomV2: payload } = await executeRequest({
        operation: createClassroomV2,
        params: { input },
    });
    return payload;
};

async function listClassroomInstructors(classroomId, providerArn) {
    try {
        const { listClassroomRelationships: payload } = await executeRequest({
            operation: listClassroomRelationships,
            params: {
                input: { classroomId, providerArn, relationship: 'instructor' },
            },
        });
        return {
            classroomId,
            emails: payload.emails,
        };
    } catch (err) {
        if (err?.statusCode === HTTP_STATUS.NOT_IMPLEMENTED) {
            return {
                classroomId,
                emails: ['not-implemented@amazon.com'],
            };
        }
        throw err;
    }
}

const listClassroomOperations = {
    listUserClassrooms,
    listProviderClassrooms,
};

async function listClassrooms({ providerArn, operation, nextToken, after }) {
    if (!providerArn) {
        return {
            classrooms: [],
            courseIds: [],
        };
    }
    const data = {
        classrooms: [],
        nextToken: null,
    };
    try {
        const response = await executeRequest({
            operation: listClassroomOperations[operation],
            params: {
                input: {
                    providerArn,
                    nextToken,
                    fullFilter: {
                        after,
                        attribute: 'endsOn',
                    },
                    maxResults: 10,
                },
            },
        });
        const payload = response[operation];
        data.classrooms = payload.classrooms;
        data.nextToken = payload.nextToken;
    } catch (err) {
        if (err?.statusCode === HTTP_STATUS.NOT_IMPLEMENTED) {
            data.classrooms = mockListClassrooms;
        } else {
            throw err;
        }
    }

    // modify the shape of the data to make it easily displayable by the Polaris Table component
    data.classrooms.forEach(classroom => {
        classroom.classroomId = classroom.classroomArn.split('/').pop();
        classroom.startDate = moment
            .tz(classroom.startsOn * 1000, classroom.locationData.timezone)
            .format('MM/DD/YY');
        classroom.endsOn = moment
            .tz(classroom.endsOn * 1000, classroom.locationData.timezone)
            .format('MM/DD/YY');

        classroom.country = classroom.locationData?.physicalAddress?.country;
    });

    data.courseIds = uniq(data.classrooms.map(({ courseId }) => courseId));
    return data;
}

export {
    HTTP_STATUS,
    executeRequest,
    handleHttpErrorResponse,
    getHash,
    getCourses,
    getAllCourseVersions,
    getCourseVersionDetails,
    getProviders,
    sendClientLog,
    getProviderData,
    getUserRoles,
    updateClassroom,
    createClassroom,
    addInstructorsToClassroom,
    listClassrooms,
    listClassroomInstructors,
};
