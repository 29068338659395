import { useEffect, useState } from 'react';
import { acceptInvitations } from 'graphql/mutations';
import { executeRequest } from 'modules/api';
import { FETCH_ERROR_NAME, recordError } from 'utils/error';

export const useAcceptInvitations = () => {
    const [acceptedInvitations, acceptedInvitationsSet] = useState(null);

    useEffect(() => {
        executeRequest({
            operation: acceptInvitations,
        })
            .then(({ acceptInvitations: payload }) => {
                acceptedInvitationsSet(payload.acceptedInvitations);
            })
            .catch(err => {
                recordError({
                    message: err.message,
                    type: FETCH_ERROR_NAME,
                });
            });
    }, []);

    return acceptedInvitations;
};
