import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    pageTitle: {
        id: 'ProvidersPage.pageTitle',
        description: 'Provider selection page title',
        defaultMessage: 'Providers',
    },
    providerSelectionTitle: {
        id: 'ProvidersPage.title',
        description: 'Provider selection title',
        defaultMessage: 'Select AWS training provider',
    },
    loadingProviders: {
        id: 'ProvidersPage.loading',
        description: 'Text to signal providers are loading',
        defaultMessage: 'Loading providers',
    },
    noProviders: {
        id: 'ProvidersPage.noProviders',
        description: 'Text to signal no providers are associated with the user',
        defaultMessage: 'No associated providers',
    },
    loadingFailure: {
        id: 'ProvidersPage.loadingFailure',
        description: 'When loading the providers fails',
        defaultMessage: 'There was a problem loading your providers, please reload the page.',
    },
});

export default messages;
