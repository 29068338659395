import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    pageTitle: {
        id: 'ClassListPage.tcPageTitle',
        description: 'ClassList page title',
        defaultMessage: 'Classes',
    },
    createClass: {
        id: 'ClassListPage.createClass',
        description: 'link to create class page',
        defaultMessage: 'Create class',
    },
    activeLabel: {
        id: 'ClassListPage.activeLabel',
        description: 'Active classes text',
        defaultMessage: 'Active classes',
    },
    futureLabel: {
        id: 'ClassListPage.futureLabel',
        description: 'Future classes text',
        defaultMessage: 'Upcoming classes',
    },
});

export default messages;
