export const getClassroomWithInstructors = /* GraphQL */ `
    query GetClassroomWithInstructors($classroomId: String!, $providerArn: String!) {
        listClassroomRelationships(
            input: {
                classroomId: $classroomId
                providerArn: $providerArn
                relationship: instructor
            }
        ) {
            emails
        }
        getClassroom(classroomId: $classroomId, providerArn: $providerArn) {
            classroom {
                classroomId
                langLocale
                courseId
                providerArn
                courseTitle
                courseAbstract
                courseDescription
                courseLevel
                startsOn
                endsOn
                locationData {
                    timezone
                    displayName
                    longitude
                    latitude
                    locationType
                    virtualUrl
                    physicalAddress {
                        addressLine1
                        addressLine2
                        city
                        state
                        postalCode
                        country
                    }
                }
                studentGuide {
                    custom_vbid
                }
                callsToAction {
                    locationType
                    url
                    label
                    actionType
                    icon
                    iconPosition
                }
                accessCodes {
                    accessCode
                    accessCodeCapacity
                    accessCodeCapacityUsed
                }
                classCapacity
                supportedRegions
                createdBy
            }
            course {
                courseId
                title
                description
                langLocale
                abstract
                level
            }
            content {
                contentId
                title
                description
                level
                duration
                durationUnit
                isActive
                supportedRegions
            }
        }
    }
`;
