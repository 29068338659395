import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
    selectedLabel: {
        id: 'timeZoneSelect.selectedLabel',
        defaultMessage: 'Selected',
    },
    timeZoneLabel: {
        id: 'timeZoneSelect.timeZoneLabel',
        defaultMessage: '(GMT{direction}{hour})',
    },
    timeZonePlaceholder: {
        id: 'timeZoneSelect.timeZonePlaceholder',
        defaultMessage: 'Select time zone',
    },
});

export const timezones = defineMessages({
    'Pacific/Midway': {
        id: 'timeZoneSelect.Pacific/Midway',
        defaultMessage: 'Midway Island, Samoa',
    },
    'Pacific/Honolulu': {
        id: 'timeZoneSelect.Pacific/Honolulu',
        defaultMessage: 'Hawaii',
    },
    'America/Juneau': {
        id: 'timeZoneSelect.America/Juneau',
        defaultMessage: 'Alaska',
    },
    'America/Los_Angeles': {
        id: 'timeZoneSelect.America/Los_Angeles',
        defaultMessage: 'Pacific Time (US and Canada); Tijuana',
    },
    'America/Boise': {
        id: 'timeZoneSelect.America/Boise',
        defaultMessage: 'Mountain Time (US and Canada)',
    },
    'America/Chihuahua': {
        id: 'timeZoneSelect.America/Chihuahua',
        defaultMessage: 'Chihuahua, La Paz, Mazatlan',
    },
    'America/Phoenix': {
        id: 'timeZoneSelect.America/Phoenix',
        defaultMessage: 'Arizona',
    },
    'America/Chicago': {
        id: 'timeZoneSelect.America/Chicago',
        defaultMessage: 'Central Time (US and Canada)',
    },
    'America/Regina': {
        id: 'timeZoneSelect.America/Regina',
        defaultMessage: 'Saskatchewan',
    },
    'America/Mexico_City': {
        id: 'timeZoneSelect.America/Mexico_City',
        defaultMessage: 'Guadalajara, Mexico City, Monterrey',
    },
    'America/Belize': {
        id: 'timeZoneSelect.America/Belize',
        defaultMessage: 'Central America',
    },
    'America/Detroit': {
        id: 'timeZoneSelect.America/Detroit',
        defaultMessage: 'Eastern Time (US and Canada)',
    },
    'America/Indiana/Indianapolis': {
        id: 'timeZoneSelect.America/Indiana/Indianapolis',
        defaultMessage: 'Indiana (East)',
    },
    'America/Bogota': {
        id: 'timeZoneSelect.America/Bogota',
        defaultMessage: 'Bogota, Lima, Quito',
    },
    'America/Glace_Bay': {
        id: 'timeZoneSelect.America/Glace_Bay',
        defaultMessage: 'Atlantic Time (Canada)',
    },
    'America/Caracas': {
        id: 'timeZoneSelect.America/Caracas',
        defaultMessage: 'Caracas, La Paz',
    },
    'America/Santiago': {
        id: 'timeZoneSelect.America/Santiago',
        defaultMessage: 'Santiago',
    },
    'America/St_Johns': {
        id: 'timeZoneSelect.America/St_Johns',
        defaultMessage: 'Newfoundland and Labrador',
    },
    'America/Sao_Paulo': {
        id: 'timeZoneSelect.America/Sao_Paulo',
        defaultMessage: 'Brasilia',
    },
    'America/Argentina/Buenos_Aires': {
        id: 'timeZoneSelect.America/Argentina/Buenos_Aires',
        defaultMessage: 'Buenos Aires, Georgetown',
    },
    'America/Godthab': {
        id: 'timeZoneSelect.America/Godthab',
        defaultMessage: 'Greenland',
    },
    'Etc/GMT+2': {
        id: 'timeZoneSelect.Etc/GMT+2',
        defaultMessage: 'Mid-Atlantic',
    },
    'Atlantic/Azores': {
        id: 'timeZoneSelect.Atlantic/Azores',
        defaultMessage: 'Azores',
    },
    'Atlantic/Cape_Verde': {
        id: 'timeZoneSelect.Atlantic/Cape_Verde',
        defaultMessage: 'Cape Verde Islands',
    },
    GMT: {
        id: 'timeZoneSelect.GMT',
        defaultMessage: 'Dublin, Edinburgh, Lisbon, London',
    },
    'Africa/Casablanca': {
        id: 'timeZoneSelect.Africa/Casablanca',
        defaultMessage: 'Casablanca, Monrovia',
    },
    'Atlantic/Canary': {
        id: 'timeZoneSelect.Atlantic/Canary',
        defaultMessage: 'Canary Islands',
    },
    'Europe/Belgrade': {
        id: 'timeZoneSelect.Europe/Belgrade',
        defaultMessage: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    },
    'Europe/Sarajevo': {
        id: 'timeZoneSelect.Europe/Sarajevo',
        defaultMessage: 'Sarajevo, Skopje, Warsaw, Zagreb',
    },
    'Europe/Brussels': {
        id: 'timeZoneSelect.Europe/Brussels',
        defaultMessage: 'Brussels, Copenhagen, Madrid, Paris',
    },
    'Europe/Amsterdam': {
        id: 'timeZoneSelect.Europe/Amsterdam',
        defaultMessage: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    },
    'Africa/Algiers': {
        id: 'timeZoneSelect.Africa/Algiers',
        defaultMessage: 'West Central Africa',
    },
    'Europe/Bucharest': {
        id: 'timeZoneSelect.Europe/Bucharest',
        defaultMessage: 'Bucharest',
    },
    'Africa/Cairo': {
        id: 'timeZoneSelect.Africa/Cairo',
        defaultMessage: 'Cairo',
    },
    'Europe/Helsinki': {
        id: 'timeZoneSelect.Europe/Helsinki',
        defaultMessage: 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
    },
    'Europe/Athens': {
        id: 'timeZoneSelect.Europe/Athens',
        defaultMessage: 'Athens, Istanbul, Minsk',
    },
    'Asia/Jerusalem': {
        id: 'timeZoneSelect.Asia/Jerusalem',
        defaultMessage: 'Jerusalem',
    },
    'Africa/Harare': {
        id: 'timeZoneSelect.Africa/Harare',
        defaultMessage: 'Harare, Pretoria',
    },
    'Europe/Moscow': {
        id: 'timeZoneSelect.Europe/Moscow',
        defaultMessage: 'Moscow, St. Petersburg, Volgograd',
    },
    'Asia/Kuwait': {
        id: 'timeZoneSelect.Asia/Kuwait',
        defaultMessage: 'Kuwait, Riyadh',
    },
    'Africa/Nairobi': {
        id: 'timeZoneSelect.Africa/Nairobi',
        defaultMessage: 'Nairobi',
    },
    'Asia/Baghdad': {
        id: 'timeZoneSelect.Asia/Baghdad',
        defaultMessage: 'Baghdad',
    },
    'Asia/Tehran': {
        id: 'timeZoneSelect.Asia/Tehran',
        defaultMessage: 'Tehran',
    },
    'Asia/Dubai': {
        id: 'timeZoneSelect.Asia/Dubai',
        defaultMessage: 'Abu Dhabi, Muscat',
    },
    'Asia/Baku': {
        id: 'timeZoneSelect.Asia/Baku',
        defaultMessage: 'Baku, Tbilisi, Yerevan',
    },
    'Asia/Kabul': {
        id: 'timeZoneSelect.Asia/Kabul',
        defaultMessage: 'Kabul',
    },
    'Asia/Yekaterinburg': {
        id: 'timeZoneSelect.Asia/Yekaterinburg',
        defaultMessage: 'Ekaterinburg',
    },
    'Asia/Karachi': {
        id: 'timeZoneSelect.Asia/Karachi',
        defaultMessage: 'Islamabad, Karachi, Tashkent',
    },
    'Asia/Kolkata': {
        id: 'timeZoneSelect.Asia/Kolkata',
        defaultMessage: 'Chennai, Kolkata, Mumbai, New Delhi',
    },
    'Asia/Kathmandu': {
        id: 'timeZoneSelect.Asia/Kathmandu',
        defaultMessage: 'Kathmandu',
    },
    'Asia/Dhaka': {
        id: 'timeZoneSelect.Asia/Dhaka',
        defaultMessage: 'Astana, Dhaka',
    },
    'Asia/Colombo': {
        id: 'timeZoneSelect.Asia/Colombo',
        defaultMessage: 'Sri Jayawardenepura',
    },
    'Asia/Almaty': {
        id: 'timeZoneSelect.Asia/Almaty',
        defaultMessage: 'Almaty, Novosibirsk',
    },
    'Asia/Rangoon': {
        id: 'timeZoneSelect.Asia/Rangoon',
        defaultMessage: 'Yangon Rangoon',
    },
    'Asia/Bangkok': {
        id: 'timeZoneSelect.Asia/Bangkok',
        defaultMessage: 'Bangkok, Hanoi, Jakarta',
    },
    'Asia/Krasnoyarsk': {
        id: 'timeZoneSelect.Asia/Krasnoyarsk',
        defaultMessage: 'Krasnoyarsk',
    },
    'Asia/Shanghai': {
        id: 'timeZoneSelect.Asia/Shanghai',
        defaultMessage: 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
    },
    'Asia/Kuala_Lumpur': {
        id: 'timeZoneSelect.Asia/Kuala_Lumpur',
        defaultMessage: 'Kuala Lumpur, Singapore',
    },
    'Asia/Taipei': {
        id: 'timeZoneSelect.Asia/Taipei',
        defaultMessage: 'Taipei',
    },
    'Australia/Perth': {
        id: 'timeZoneSelect.Australia/Perth',
        defaultMessage: 'Perth',
    },
    'Asia/Irkutsk': {
        id: 'timeZoneSelect.Asia/Irkutsk',
        defaultMessage: 'Irkutsk, Ulaanbaatar',
    },
    'Asia/Seoul': {
        id: 'timeZoneSelect.Asia/Seoul',
        defaultMessage: 'Seoul',
    },
    'Asia/Tokyo': {
        id: 'timeZoneSelect.Asia/Tokyo',
        defaultMessage: 'Osaka, Sapporo, Tokyo',
    },
    'Asia/Yakutsk': {
        id: 'timeZoneSelect.Asia/Yakutsk',
        defaultMessage: 'Yakutsk',
    },
    'Australia/Darwin': {
        id: 'timeZoneSelect.Australia/Darwin',
        defaultMessage: 'Darwin',
    },
    'Australia/Adelaide': {
        id: 'timeZoneSelect.Australia/Adelaide',
        defaultMessage: 'Adelaide',
    },
    'Australia/Sydney': {
        id: 'timeZoneSelect.Australia/Sydney',
        defaultMessage: 'Canberra, Melbourne, Sydney',
    },
    'Australia/Brisbane': {
        id: 'timeZoneSelect.Australia/Brisbane',
        defaultMessage: 'Brisbane',
    },
    'Australia/Hobart': {
        id: 'timeZoneSelect.Australia/Hobart',
        defaultMessage: 'Hobart',
    },
    'Asia/Vladivostok': {
        id: 'timeZoneSelect.Asia/Vladivostok',
        defaultMessage: 'Vladivostok',
    },
    'Pacific/Guam': {
        id: 'timeZoneSelect.Pacific/Guam',
        defaultMessage: 'Guam, Port Moresby',
    },
    'Asia/Magadan': {
        id: 'timeZoneSelect.Asia/Magadan',
        defaultMessage: 'Magadan, Solomon Islands, New Caledonia',
    },
    'Pacific/Fiji': {
        id: 'timeZoneSelect.Pacific/Fiji',
        defaultMessage: 'Fiji Islands, Kamchatka, Marshall Islands',
    },
    'Pacific/Auckland': {
        id: 'timeZoneSelect.Pacific/Auckland',
        defaultMessage: 'Auckland, Wellington',
    },
    'Pacific/Tongatapu': {
        id: 'timeZoneSelect.Pacific/Tongatapu',
        defaultMessage: "Nuku'alofa",
    },
});
