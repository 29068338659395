import React, { useEffect, useState, Fragment } from 'react';
import { useIntl, FormattedDate } from 'react-intl';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import { ColumnLayout, Input, Button, Flashbar } from '@amzn/awsui-components-react';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';

import { Loader, AccessCodeTable, PageWrapper, Breadcrumbs } from 'components';
import { LOCATION_TYPES } from 'components/classForm/ClassForm.utils';
import { getCourseVersionDetails } from 'modules';
import { useClassroomData } from 'data/useClassroomData';
import messages from './ClassDetailPage.messages';
import { paths } from 'utils/paths';
import { useFlags } from 'utils/flags';
import { isEmpty, copyToClipboard, getStudentURL, getDuration } from 'utils/helpers';

import './ClassDetailPage.css';
import { classDetailPageBreadcrumb, classesPageBreadcrumb } from 'utils/breadcrumbs';

/**
 * Custom method that returns time in a formatted string with
 * short time zone name, ex: 9:00 AM- 10:00 AM
 * @param {Number} value Date value to be formatted, expected in unix format
 * @param {String} timezone Timezone for date value
 * @return {String} Formatted value of time based on locale settings
 */
const formatTime = (value, timezone) =>
    moment
        .unix(value)
        .tz(timezone)
        .format('hh:mm A');

const Container = ({ children, headerText, description }) => (
    <div className="awsui-util-container">
        <div className="awsui-util-container-header">
            <h2>{headerText}</h2>
            {description ? (
                <div className="awsui-util-container-header-description">{description}</div>
            ) : null}
        </div>

        <div className="class-detail__container">{children}</div>
    </div>
);

const buildFlashbarItems = formatMessage => [
    {
        type: 'info',
        dismissible: true,
        content: formatMessage(messages.modalMainContent),
    },
];

const ClassDetailPage = props => {
    const { formatMessage, locale } = useIntl();
    const history = useHistory();
    const {
        match: {
            params: { classroomId },
        },
        globals,
    } = props;
    const flags = useFlags();
    const classData = useClassroomData(classroomId, {
        withConsistencyRetry: true,
        withInstructors: flags.instructorAssignment,
    });
    const [urlCopied, setUrlCopied] = useState(false);
    const [courseVersionDetails, courseVersionDetailsSet] = useState({});
    const instructorURL = `${globals.location.origin}${paths.classPage(classroomId)}`;
    const instructors = classData?.classroom?.instructors || [];

    useEffect(() => {
        const courseArn = _get(classData, 'course.courseId', '');
        if (courseArn) {
            getCourseVersionDetails(courseArn).then(courseVersionDetailsSet);
        }
    }, [classData]);

    useEffect(() => {
        const handler = e => {
            if (!urlCopied) {
                e.preventDefault();
                // Chrome-specific hack
                if (e.defaultPrevented) {
                    e.returnValue = '';
                }
            }
        };

        if (!flags.showClassList) {
            globals.addEventListener('beforeunload', handler);
        }

        return () => {
            if (!flags.showClassList) {
                globals.removeEventListener('beforeunload', handler);
            }
        };
    }, [globals, urlCopied, flags.showClassList]);

    const { virtualUrl, locationType, timezone = moment.tz.guess(), physicalAddress } = _get(
        classData,
        'classroom.locationData',
        {}
    );
    const { addressLine1, addressLine2, city, state, postalCode, country } = physicalAddress || {};

    const startsOn = _get(classData, 'classroom.startsOn', 0);
    const endsOn = _get(classData, 'classroom.endsOn', 0);
    const classCapacity = _get(classData, 'classroom.classCapacity', 0);
    const trainingProvider = _get(classData, 'classroom.trainingProvider');
    const createdBy = _get(classData, 'classroom.createdBy');
    const editDisabled = moment().isAfter(moment.unix(endsOn));

    const startTime = formatTime(startsOn, timezone);
    const endTime = formatTime(endsOn, timezone);
    const timeFormatted = `${startTime} - ${endTime}`;

    const copyUrlClickHandler = () => {
        setUrlCopied(true);
        copyToClipboard({ globals, value: instructorURL });
    };

    return (
        <>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <Loader isLoading={isEmpty(classData)}>
                <Flashbar items={buildFlashbarItems(formatMessage)}></Flashbar>
                <PageWrapper>
                    <Breadcrumbs
                        items={[
                            classesPageBreadcrumb(formatMessage),
                            classDetailPageBreadcrumb(formatMessage, classroomId),
                        ]}
                    />
                    {trainingProvider ? (
                        <h1 className="awsui-text-large awsui-util-pb-l">{trainingProvider}</h1>
                    ) : null}
                    <div className="awsui-util-action-stripe-large">
                        <div className="awsui-util-action-stripe-title">
                            <h2>{formatMessage(messages.pageTitle)}</h2>
                        </div>

                        <div className="awsui-util-action-stripe-group">
                            <Button
                                variant="normal"
                                data-testid="create-class-btn"
                                label={formatMessage(messages.createClassButtonLabel)}
                                onClick={() => history.push(paths.classPage('new'))}
                            >
                                {formatMessage(messages.createClassButtonText)}
                            </Button>
                            <Button
                                variant="normal"
                                disabled={editDisabled}
                                label={formatMessage(messages.editButtonLabel)}
                                onClick={() => history.push(paths.classEditPage(classroomId))}
                            >
                                {formatMessage(messages.editButtonText)}
                            </Button>
                        </div>
                    </div>
                    <Container headerText={formatMessage(messages.classInformationHeader)}>
                        <ColumnLayout columns={4} borders="vertical">
                            <div data-awsui-column-layout-root="true">
                                <dl>
                                    <dt>{formatMessage(messages.courseTitleLabel)}</dt>
                                    <dd>{_get(classData, 'course.title', '')}</dd>
                                </dl>
                                <dl>
                                    <dt>{formatMessage(messages.courseVersionLabel)}</dt>
                                    <dd>
                                        {_get(classData, 'course.courseId', '')
                                            .split(':')
                                            .pop()}
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>{formatMessage(messages.courseContentVersionLabel)}</dt>
                                    <dd>{_get(courseVersionDetails, 'contentVersion', '-')}</dd>
                                </dl>
                                <dl>
                                    <dt>{formatMessage(messages.courseLanguageLabel)}</dt>
                                    <dd>{_get(classData, 'course.langLocale', '')}</dd>
                                </dl>
                                <dl>
                                    <dt className="awsui-util-label">
                                        {formatMessage(messages.dateLabel)}
                                    </dt>
                                    <dd>
                                        <FormattedDate
                                            value={moment.unix(startsOn).valueOf()}
                                            timeZone={timezone}
                                        />{' '}
                                        -{' '}
                                        <FormattedDate
                                            value={moment.unix(endsOn).valueOf()}
                                            timeZone={timezone}
                                        />
                                    </dd>
                                </dl>
                                <dl>
                                    <dt className="awsui-util-label">
                                        {formatMessage(messages.timeLabel)}
                                    </dt>
                                    <dd>{timeFormatted}</dd>
                                </dl>
                                <dl>
                                    <dt className="awsui-util-label">
                                        {formatMessage(messages.durationLabel)}
                                    </dt>
                                    <dd>{getDuration(startsOn, endsOn, locale)}</dd>
                                </dl>
                                <dl>
                                    <dt className="awsui-util-label">
                                        {formatMessage(messages.timeZoneLabel)}
                                    </dt>
                                    <dd>{timezone}</dd>
                                </dl>
                                <dl>
                                    <dt className="awsui-util-label">
                                        {formatMessage(messages.trainingProviderLabel)}
                                    </dt>
                                    <dd>{trainingProvider}</dd>
                                </dl>
                                <dl>
                                    <dt className="awsui-util-label">
                                        {formatMessage(messages.classNumberOfStudents)}
                                    </dt>
                                    <dd>{classCapacity}</dd>
                                </dl>
                                {createdBy ? (
                                    <dl>
                                        <dt className="awsui-util-label">
                                            {formatMessage(messages.classCreatedBy)}
                                        </dt>
                                        <dd>{createdBy}</dd>
                                    </dl>
                                ) : null}
                                <dl>
                                    <dt className="awsui-util-label">
                                        {formatMessage(messages.classInstructor)}
                                    </dt>
                                    {instructors.length ? (
                                        instructors.map(email => <dd key={email}>{email}</dd>)
                                    ) : (
                                        <dd>-</dd>
                                    )}
                                </dl>
                            </div>
                        </ColumnLayout>
                    </Container>
                    <AccessCodeTable
                        accessCodes={_get(classData, 'classroom.accessCodes', [])}
                        studentUrl={getStudentURL(classroomId)}
                    />
                    <Container headerText={formatMessage(messages.classLocationHeader)}>
                        {locationType === LOCATION_TYPES.virtual ? (
                            <Fragment>
                                <dl>
                                    <dt className="awsui-util-label">
                                        {formatMessage(messages.virtualClassUrlLabel)}
                                    </dt>
                                    <dd className="class-detail__flex-between">
                                        <Button
                                            variant="icon"
                                            icon="external"
                                            label={formatMessage(messages.openVirtualClassUrlLabel)}
                                            href={virtualUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        />
                                        <Input // using input for easier to copy text
                                            label={formatMessage(messages.virtualClassUrlLabel)}
                                            ariaRequired
                                            readonly
                                            value={virtualUrl}
                                        />
                                    </dd>
                                </dl>
                            </Fragment>
                        ) : null}
                        {locationType === LOCATION_TYPES.physical ? (
                            <dl>
                                <dt className="awsui-util-label">
                                    {formatMessage(messages.classAddressLabel)}
                                </dt>
                                <dd>{addressLine1}</dd>
                                <dd>{addressLine2}</dd>
                                <dd>
                                    {city}, {state} {postalCode}, {country}
                                </dd>
                            </dl>
                        ) : null}
                        <dl>
                            <dt className="awsui-util-label">
                                {formatMessage(messages.instructorUrlLabel)}
                                <i data-testid="modal-link">
                                    - {formatMessage(messages.instructorUrlModalNotice)}
                                </i>
                            </dt>
                            <dd className="class-detail__flex-between">
                                <a
                                    href={paths.classPage(classroomId)}
                                    rel="noopener noreferrer"
                                    aria-label={formatMessage(messages.instructorUrlLabel)}
                                >
                                    {instructorURL}
                                </a>
                                <Button
                                    variant="normal"
                                    label={formatMessage(messages.copyToClipboard)}
                                    onClick={copyUrlClickHandler}
                                >
                                    {formatMessage(
                                        urlCopied ? messages.urlCopied : messages.copyToClipboard
                                    )}
                                </Button>
                            </dd>
                        </dl>
                    </Container>
                </PageWrapper>
            </Loader>
        </>
    );
};

export default ClassDetailPage;
