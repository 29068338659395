import { nanoid } from 'nanoid';

export const UUID_KEY = 'aws.beaker.clientLoggingUUID';

export const createLogMessage = ({ payload, globals }) => {
    let userId = globals.localStorage.getItem(UUID_KEY);

    if (!userId) {
        userId = nanoid();
        globals.localStorage.setItem(UUID_KEY, userId);
    }

    return {
        userId,
        ...payload,
    };
};
