import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Icon, Toggle } from '@amzn/awsui-components-react';

import ingressMessages from 'components/ingressTable/IngressTable.messages';
import labCardMessages from './LabCard.messages';
import './LabCard.css';

const LabCard = ({ title, contentId, duration, description, index, active, onChange }) => {
    const { formatMessage } = useIntl();
    const { pathname } = useLocation();
    const history = useHistory();
    const toggleLabel = formatMessage(active ? ingressMessages.active : ingressMessages.inactive);
    const ingressUrl = `${pathname}/labs/${encodeURIComponent(contentId)}`;
    const btnOnClick = () => history.push(ingressUrl);
    return (
        <div className="lab-card" data-testid={`lab-card-${index}`}>
            <div className="lab-card__header">
                <h3>{title}</h3>
                <span>
                    <Icon name="status-pending" variant="subtle" />
                    &nbsp; {duration}
                </span>
            </div>
            <p>{description}</p>
            <div className="lab-card__actions">
                <Button onClick={btnOnClick}>{formatMessage(labCardMessages.labsBtnLabel)}</Button>
                <Toggle
                    className="lab-card__toggle"
                    checked={active}
                    onChange={onChange}
                    data-testid={`toggle-activation-${index}`}
                >
                    {toggleLabel}
                </Toggle>
            </div>
        </div>
    );
};

export default LabCard;
