const ROOT_URL = '/';
const PROVIDERS_PAGE = '/providers';
const CLASSES_PAGE = '/classes';
const LANDING_PAGE = CLASSES_PAGE;

export const paths = {
    root: ROOT_URL,
    landingPage: LANDING_PAGE,
    providersPage: PROVIDERS_PAGE,
    classListPage: CLASSES_PAGE,
    classPage: classId => `/class/${classId}`,
    classDetailPage: classId => `/class/${classId}/detail`,
    classEditPage: classId => `/class/${classId}/edit`,
    labPage: (classId, labId) => `/class/${classId}/labs/${labId}`,
};

// A user should be returned to their target page (if defined) with a fallback to the landing page
export const redirect = (globals, features) => {
    const landingPage = features.showClassList ? LANDING_PAGE : '/class/new';
    const pathFromStorage = globals.sessionStorage.getItem('redirect_pathname');
    return pathFromStorage && pathFromStorage !== ROOT_URL ? pathFromStorage : landingPage;
};
export const parameters = {
    classroomId: ':classroomId',
    labId: ':labId',
};

export const isLanguageCustomizablePage = pathname => {
    // eslint-disable-next-line no-unused-vars
    const [empty, sub1, classId, sub2] = pathname.split('/');
    // check against an allow-list of pages
    return (
        [
            paths.root,
            paths.landingPage,
            paths.providersPage,
            paths.classListPage,
            paths.classPage('new'),
        ].includes(pathname) || ['detail', 'edit'].includes(sub2)
    );
};
