import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import { SideNavigation } from '@amzn/awsui-components-react';

const LabNavigation = ({ content, labTitle, setLabNumber, onLabSelection, labNumber }) => {
    const history = useHistory();
    const { classroomId, labId } = useParams();
    const activeHref = generatePath('/class/:classroomId/labs/:labId', {
        classroomId,
        labId,
    });
    return (
        <SideNavigation
            activeHref={activeHref}
            items={content.map((lab, index) => {
                return {
                    type: 'link',
                    text: `${labTitle} ${index + 1}`,
                    href: generatePath('/class/:classroomId/labs/:labId', {
                        classroomId,
                        labId: encodeURIComponent(lab.contentId),
                    }),
                    index,
                };
            })}
            onFollow={e => {
                e.preventDefault();
                if (e.detail.index !== labNumber) {
                    onLabSelection();
                    setLabNumber(e.detail.index);
                    history.push(e.detail.href);
                }
            }}
        />
    );
};

export default LabNavigation;
