import { defineMessages } from 'react-intl.macro';

const ingressTableMessages = defineMessages({
    tableEmpty: {
        id: 'ingress.table.empty',
        defaultMessage: 'No trainings to display.',
    },
    tableHeaderStudent: {
        id: 'ingress.header.student',
        defaultMessage: 'Student',
    },
    tableHeaderLabStatus: {
        id: 'ingress.header.labStatus',
        defaultMessage: 'Lab Status',
    },
    tableHeaderRegion: {
        id: 'ingress.header.region',
        defaultMessage: 'Region',
    },
    tableHeaderControls: {
        id: 'ingress.header.controls',
        defaultMessage: 'Access Controls',
    },
    ingressControl: {
        id: 'ingress.button.ingress',
        defaultMessage: 'Ingress',
    },
    cannotIngress: {
        id: 'ingress.modal.cannotIngress',
        defaultMessage: 'Cannot Ingress',
    },
    close: {
        id: 'ingress.modal.studentLabEnded.close',
        defaultMessage: 'Close',
    },
    closeModalLabelText: {
        id: 'ingress.modal.closeLabelText',
        defaultMessage: 'Close dialog',
    },
    studentLabEnded: {
        id: 'ingress.modal.studentLabEnded',
        defaultMessage: "The student's lab has ended",
    },
    ingressingControl: {
        id: 'ingress.button.ingressing',
        defaultMessage: 'Ingressing',
    },
    labTitle: {
        id: 'ingress.lab.title',
        defaultMessage: 'Lab',
    },
    refresh: {
        id: 'ingress.button.refresh',
        defaultMessage: 'Refresh',
    },
    active: {
        id: 'ingress.Lab.active',
        description: 'The text to display for an active lab',
        defaultMessage: 'Active',
    },
    inactive: {
        id: 'ingress.Lab.inactive',
        description: 'The text to display for an inactive lab',
        defaultMessage: 'Inactive',
    },
});

export default ingressTableMessages;
