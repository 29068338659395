import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
    courseTitle: {
        id: 'ClassTable.CourseTitle',
        defaultMessage: 'Course title',
    },
    startDate: {
        id: 'ClassTable.StartDate',
        defaultMessage: 'Start date',
    },
    endsOnDate: {
        id: 'ClassTable.EndDate',
        defaultMessage: 'End date',
    },
    deliveryMethod: {
        id: 'ClassTable.DeliveryMethod',
        defaultMessage: 'Delivery method',
    },
    instructorList: {
        id: 'ClassTable.InstructorAscending',
        defaultMessage: 'Instructor',
    },
    timezone: {
        id: 'ClassTable.Timezone',
        defaultMessage: 'Timezone',
    },
    location: {
        id: 'ClassTable.Location',
        defaultMessage: 'Location',
    },

    classListHeading: {
        id: 'ClassTable.classListHeading',
        description: 'List of classes to display to user',
        defaultMessage: 'Classes',
    },

    virtualLocation: {
        id: 'ClassTable.virtual',
        description: 'Location to display when location is virtual',
        defaultMessage: 'Virtual',
    },
    physicalLocation: {
        id: 'ClassTable.physical',
        description: 'Location to display when location is physical',
        defaultMessage: 'In-person',
    },
    noClasses: {
        id: 'ClassTable.noClasses',
        description: 'When no classes are returned from the backend',
        defaultMessage: 'No classes',
    },
    noClassesToDisplay: {
        id: 'ClassTable.noClassesToDisplay',
        description: 'When no classes are returned from the backend',
        defaultMessage: 'No classes to display.',
    },
    viewAllActiveClasses: {
        id: 'ClassTable.viewAllActiveClasses',
        description: 'button text to view all active classes',
        defaultMessage: 'View all active classes',
    },
    viewAllFutureClasses: {
        id: 'ClassTable.viewAllFutureClasses',
        description: 'button text to view all upcoming classes',
        defaultMessage: 'View all upcoming classes',
    },
    loadingResources: {
        id: 'ClassTable.loadingResources',
        description: 'text describing the table loading data',
        defaultMessage: 'Loading resources',
    },
    previousPageLabel: {
        id: 'ClassTable.pagination.previousPageLabel',
        description: 'text for previous page button for pagination',
        defaultMessage: 'Previous page',
    },
    nextPageLabel: {
        id: 'ClassTable.pagination.nextPageLabel',
        description: 'text for next page button for pagination',
        defaultMessage: 'Next page',
    },
    pageLabel: {
        id: 'ClassTable.pagination.pageLabel',
        description: 'text for each page button for pagination',
        defaultMessage: 'Go to page {pageNumber}',
    },
});
