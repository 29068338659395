import { defineMessages } from 'react-intl.macro';

const labCardMessages = defineMessages({
    labsBtnLabel: {
        id: 'labCard.btn.label',
        defaultMessage: 'Manage Student Labs',
    },
});

export default labCardMessages;
