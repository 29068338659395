export const addCourseTitleToClassroomData = (classrooms, courses) => {
    return classrooms.map(classroom => {
        const courseId = classroom.courseId.split('/').pop();
        const courseData = courses.find(
            (course = {}) => `${course.courseName}:${course.courseVersionId}` === courseId
        );
        if (courseData) {
            classroom.courseTitle = courseData.resourceData.find(
                ({ locale }) => locale === classroom.langLocale
            ).title;
        }
        return classroom;
    });
};

export const addInstructorsToClassroomData = (classrooms, instructors) => {
    return classrooms.map(classroom => {
        const instructorEmails = instructors.find(
            ({ classroomId } = {}) => classroomId === classroom.classroomId
        );
        if (instructorEmails) {
            classroom.instructors = instructorEmails.emails;
        }
        return classroom;
    });
};

export const addSupplementalClassroomData = (classrooms, courses, instructors) => {
    const data = addCourseTitleToClassroomData(classrooms, courses);
    return addInstructorsToClassroomData(data, instructors);
};
