import React from 'react';
import { useQuery } from 'react-query';
import _get from 'lodash/get';
import { getCurrentUser } from 'utils/helpers';
import { ProviderCtx } from 'data/ProviderContext';
import { getUserRoles } from 'modules';
import { useFlags } from './flags';

const TC_RELATIONSHIP_TYPE = 'training_coordinator';

const getUserEmail = async () => {
    const { email, ...rest } = await getCurrentUser();
    return email || `${rest['cognito:username']}@amazon.com`;
};

const useQueryOptions = { refetchOnWindowFocus: false };

export const UserInfoCtx = React.createContext();

export const UserInfoProvider = ({ children }) => {
    const providerArn = _get(React.useContext(ProviderCtx), 'provider.arn');
    const { instructorAssignment } = useFlags();
    const { data: email, isLoading: loadingEmail } = useQuery(
        'userEmail',
        getUserEmail,
        useQueryOptions
    );
    const { data: roles, isLoading: loadingRoles } = useQuery(
        ['userRoles', providerArn],
        () => (instructorAssignment && providerArn ? getUserRoles(providerArn) : null),
        useQueryOptions
    );

    const userIsTrainingCoordinator =
        instructorAssignment && (roles || []).includes(TC_RELATIONSHIP_TYPE);

    const userInfo = {
        email,
        roles,
        userIsTrainingCoordinator,
        isLoading: loadingEmail || loadingRoles,
    };

    return <UserInfoCtx.Provider value={userInfo}>{children}</UserInfoCtx.Provider>;
};

export const useUserInfo = () => React.useContext(UserInfoCtx);
