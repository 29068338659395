import React, { useState } from 'react';
import { Modal } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import { ClassForm, Loader, PageWrapper, Breadcrumbs } from 'components';
import { updateClassroom, addInstructorsToClassroom } from 'modules';
import { useClassroomData } from 'data/useClassroomData';
import { isEmpty } from 'utils/helpers';
import messages from './ClassEditPage.messages';
import { useFlags } from 'utils/flags';
import {
    classDetailPageBreadcrumb,
    classEditPageBreadcrumb,
    classesPageBreadcrumb,
} from 'utils/breadcrumbs';

const errorMessages = {
    'updateClassroom::409': messages.updateClass409Error,
    default: messages.notUpdatedContent,
};

const ClassEditPage = props => {
    const {
        match: {
            params: { classroomId },
        },
    } = props;

    const { formatMessage } = useIntl();
    const [updateError, updateErrorSet] = useState();
    const flags = useFlags();
    const classData = useClassroomData(classroomId, {
        withInstructors: flags.instructorAssignment,
    });
    const onSubmit = async data => {
        const payload = await updateClassroom(data);
        if (flags?.instructorAssignment) {
            await addInstructorsToClassroom(
                data.instructors,
                payload.classroomId,
                data.classroom.providerArn
            );
        }
        return payload;
    };

    const handleError = err => {
        const msg = errorMessages[`${err.path}::${err.statusCode}`] || errorMessages.default;
        updateErrorSet(msg);
    };

    return (
        <PageWrapper>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <Breadcrumbs
                items={[
                    classesPageBreadcrumb(formatMessage),
                    classDetailPageBreadcrumb(formatMessage, classroomId),
                    classEditPageBreadcrumb(formatMessage, classroomId),
                ]}
            />
            <Loader isLoading={isEmpty(classData)}>
                {!isEmpty(classData) ? (
                    <ClassForm
                        data={classData?.classroom}
                        onSubmit={onSubmit}
                        classroomId={classroomId}
                        onError={handleError}
                    />
                ) : null}
            </Loader>
            {updateError ? (
                <Modal
                    visible
                    data-testid="class-edit-modal"
                    header={formatMessage(messages.notUpdatedHeader)}
                    onDismiss={() => updateErrorSet(null)}
                    closeLabel={formatMessage(messages.closeModalButtonLabel)}
                >
                    {formatMessage(updateError)}
                </Modal>
            ) : null}
        </PageWrapper>
    );
};

export default ClassEditPage;
