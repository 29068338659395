import React from 'react';
import { useHistory } from 'react-router-dom';
import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import { useFlags } from 'utils/flags';

const Breadcrumbs = ({ items }) => {
    const history = useHistory();
    const flags = useFlags();

    const onBreadcrumbClick = e => {
        e.preventDefault();
        history.push(e.detail.href);
    };

    return flags.showClassList ? (
        <BreadcrumbGroup items={items} onClick={onBreadcrumbClick} />
    ) : null;
};

export default Breadcrumbs;
