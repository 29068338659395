import React from 'react';
import { useIntl } from 'react-intl';
import { FormField, Input, FormSection, Button } from '@amzn/awsui-components-react';

import { useFlags } from 'utils/flags';
import { useUserInfo } from 'utils/userInfo';
import { messages } from '../classForm/ClassForm.messages';
import { FieldErrors } from 'components/classForm/FieldErrors';
import './InstructorAssign.css';

const InstructorAssign = ({ providerName, instructors, instructorsSet, fieldsInvalid }) => {
    const { userIsTrainingCoordinator } = useUserInfo();
    const { formatMessage } = useIntl();
    const instructorWidgetProps = {
        instructors,
        fieldsInvalid,
        add() {
            if (instructors.length < 5) {
                instructorsSet(instructors.concat(['']));
            }
        },
        remove(index) {
            if (index !== 0 || userIsTrainingCoordinator) {
                // instructors cannot delete themselves from a class
                const copy = [...instructors];
                copy.splice(index, 1);
                instructorsSet(copy);
            }
        },
        update(index, value) {
            if (index !== 0 || userIsTrainingCoordinator) {
                // instructors cannot edit themselves
                const copy = [...instructors];
                copy.splice(index, 1, value);
                instructorsSet(copy);
            }
        },
    };
    return (
        <FormSection header={formatMessage(messages.classProviderHeader)}>
            <div data-awsui-column-layout-root="true">
                <FormField stretch label={formatMessage(messages.trainingProviderLabel)}>
                    {/* Defaults to provider of class and can't be changed */}
                    <Input value={providerName} disabled />
                </FormField>
                <MultipleInstructorsWidget {...instructorWidgetProps} />
            </div>
        </FormSection>
    );
};

const MultipleInstructorsWidget = ({ instructors, fieldsInvalid, remove, add, update }) => {
    const { formatMessage } = useIntl();
    const { instructorAssignment } = useFlags();
    const { userIsTrainingCoordinator } = useUserInfo();
    return (
        <fieldset className="instructor-assign-fieldset">
            <legend>{formatMessage(messages.instructorLabel)}</legend>
            <div className="awsui-grid instructor-fields-wrapper">
                {instructors.map((value, i) => {
                    const blockFirstFieldEdit = !userIsTrainingCoordinator && i === 0;
                    const removeButtonDisable =
                        blockFirstFieldEdit || (instructors.length === 1 && value === '');
                    const removeButtonClassName = `col-xxxs-12 col-xxs-3 col-xs-3 col-s-3`;
                    const instructorFieldClassName = instructorAssignment
                        ? 'col-xxxs-12 col-xxs-9 col-xs-9 col-s-9'
                        : 'col-xxxs-12';
                    const formFieldProps = {
                        className: instructorFieldClassName,
                        stretch: true,
                    };
                    const id = `ClassForm__instructor-${i}`;
                    const invalid =
                        Array.isArray(fieldsInvalid?.instructors) && !!fieldsInvalid.instructors[i];
                    const inputProps = {
                        ariaRequired: true,
                        inputMode: 'email',
                        placeholder: formatMessage(messages.instructorInputPlaceholder),
                        disableBrowserAutocorrect: true,
                        value,
                        id,
                        invalid,
                        'data-testid': id,
                        disabled: blockFirstFieldEdit,
                        onInput: e => update(i, e.detail.value),
                    };
                    return (
                        <div className="awsui-row" key={id}>
                            <FormField {...formFieldProps}>
                                <Input {...inputProps} />
                            </FormField>
                            {instructorAssignment && (
                                <FormField className={removeButtonClassName}>
                                    <Button
                                        onClick={() => remove(i)}
                                        disabled={removeButtonDisable}
                                    >
                                        {formatMessage(messages.removeButtonText)}
                                    </Button>
                                </FormField>
                            )}
                        </div>
                    );
                })}
            </div>
            <FieldErrors fieldsInvalid={fieldsInvalid} property="instructors" />
            {instructorAssignment && (
                <FormField>
                    <Button
                        onClick={add}
                        disabled={instructors.length >= 5}
                        style={{ marginTop: '2rem' }}
                    >
                        {formatMessage(messages.addInstructorButtonText)}
                    </Button>
                </FormField>
            )}
            <small>{formatMessage(messages.maximumInstructorNote)}</small>
        </fieldset>
    );
};

export default InstructorAssign;
