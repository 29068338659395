import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import 'moment/min/locales';
import { executeRequest } from '../../modules';
import { onUpdateTrainingPermissions, updateTrainingPermissions } from '../../graphql';
import { ClassroomContext } from '../../data/ClassroomContext';
import { LabCard } from 'components';
import { isEmpty } from '../../utils';
import { nanoid } from 'nanoid';
import './LabList.css';

const translateDurationUnit = (value, unit, locale) => {
    // crlalva: to match Content API duration enum
    // https://code.amazon.com/packages/BunsenContent/blobs/mainline/--/src/libraries/data/blueprintVersionEnums.js
    if (unit === 'min') {
        unit = 'minute';
    }
    return moment
        .duration(value, unit)
        .locale(locale)
        .humanize();
};

const onToggleTraining = (classroomId, contentId, isActive) => {
    executeRequest({
        operation: updateTrainingPermissions,
        params: {
            input: {
                arn: contentId,
                classroomId,
                isActive: !isActive,
                idempotencyToken: nanoid(),
            },
        },
    });
};

const buildComponentLearningObjects = ({ content, course }, { locale }, classroomId) => {
    return content.map(content => {
        const {
            isActive,
            duration,
            durationUnit = 'minute',
            description,
            title,
            contentId,
        } = content;
        const displayDuration = translateDurationUnit(duration, durationUnit, locale);
        const toggleActive = () => onToggleTraining(classroomId, contentId, isActive);
        const sanitizedTitle = title.replace(new RegExp(`^\\s*${course.title}\\s*-\\s*`), '');
        return {
            isActive,
            title: sanitizedTitle,
            description,
            displayDuration,
            contentId,
            toggleActive,
        };
    });
};

const LabList = ({ intl }) => {
    const { classData, updateTrainingPermissions } = useContext(ClassroomContext);
    const { classroomId } = useParams();

    useEffect(() => {
        executeRequest({
            operation: onUpdateTrainingPermissions,
            onSubscribe: data => updateTrainingPermissions(data),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isEmpty(classData) ? null : (
        <div data-testid="lab-list" className="lab-list">
            {buildComponentLearningObjects(classData, intl, classroomId).map(
                (
                    { isActive, toggleActive, contentId, title, description, displayDuration },
                    index
                ) => (
                    <LabCard
                        title={title}
                        duration={displayDuration}
                        description={description}
                        contentId={contentId}
                        index={index}
                        active={isActive}
                        onChange={toggleActive}
                        key={`lab-card-${index}`}
                    />
                )
            )}
        </div>
    );
};
export default injectIntl(LabList);
